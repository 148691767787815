import { EventEmitter, Injectable } from '@angular/core';
import { MileageService } from './model-services/orders/mileage.service';
import { TimelogService } from './model-services/timelogs/timelog.service';
import { OfflineDataService } from './offlineData.service';

interface DestroyServiceOptions {
  service: OfflineDataService<any>;
  destroyAfterMillis: number;
  filterMethod: (object: any, millis: number) => boolean;
}

// Default time in milliseconds when to destroy a object
const DEFAULT_MILLIS_TO_DESTROY = 7 * 24 * 60 * 60 * 1000; // 7 Days

@Injectable({
  providedIn: 'root',
})
/**
 * Service to clean old data from device
 */
export class LocalDestroyService {
  constructor(
    // Hier müssen weitere offline verfügbare Dienste injiziert werden, damit diese im ProcessorService registriert sind
    public readonly mileageService: MileageService,
    public readonly timelogService: TimelogService
  ) {
    this.addService(this.mileageService);
    this.addService(this.timelogService);
  }

  /**
   * All registered services for local data cleaning
   */
  private services: Array<DestroyServiceOptions> = [];

  /**
   * Event wich gets triggered when the state of the destroy-action gets changed
   * (true if running, false if not)
   */
  public destroyStatusChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  /**
   * Provides all registered services wich get cleaned
   * @returns registered services with theire options
   */
  public getServices(): Array<DestroyServiceOptions> {
    return this.services;
  }

  /**
   * Fügt intern den Service ein
   * @param service to add
   * @param millis after wich time the data should get destroyed (default: 7 Days)
   */
  private addService(
    service: OfflineDataService<any>,
    millis: number = DEFAULT_MILLIS_TO_DESTROY
  ) {
    this.services.push({
      service,
      destroyAfterMillis: millis,
      filterMethod: (o, m) => {
        const current = new Date().getTime();
        const updatedAt = new Date(o.updated_at).getTime();
        const destroyAfter = new Date(updatedAt + m).getTime();
        return current > destroyAfter;
      },
    });
  }

  /**
   * Start the filtering and deletion of on-device data
   */
  public async runLocalDataCleaner() {
    this.destroyStatusChanged.emit(true);
    const allDestroyPromises = this.services.map((s) => {
      return this.findAndDestroyWithService(s);
    });
    await Promise.all(allDestroyPromises);
    this.destroyStatusChanged.emit(false);
  }

  /**
   * Finds and destroys all objects wich are outdated
   * @param opt Options from the services-array
   * @returns array of delete responses
   */
  private async findAndDestroyWithService(
    opt: DestroyServiceOptions
  ): Promise<any> {
    const oldData = (await opt.service.localWhereWithoutPaging({})).filter(
      (d) => opt.filterMethod(d, opt.destroyAfterMillis)
    );
    const allDeletePromises = oldData.map((od) =>
      opt.service.localDelete(od.id)
    );
    if (oldData.length > 0)
      console.debug(
        '[LocalDestroyWrapper] Found and destroyed ' +
          oldData.length +
          ' outdated ' +
          opt.service.objectName +
          ' objects'
      );
    return Promise.all(allDeletePromises);
  }
}
