import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { BufferstockCleanupModel } from '../models/bufferstock-cleanup';

@Injectable({
  providedIn: 'root',
})
export class BufferstockCleanupService extends OfflineDataService<BufferstockCleanupModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Warehouse::BufferstockCleanup',
      http,
      errorService,
      events,
      'warehouse/bufferstock_cleanups/',
      'warehouse_bufferstock_cleanup',
      ['created_at', 'updated_at'],
      ['positions', 'position_disposals']
    );
  }
}
