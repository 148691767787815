import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { MitsNumberBoxComponent } from './mits-number-box.component';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule],
  declarations: [MitsNumberBoxComponent],
  entryComponents: [MitsNumberBoxComponent],
  exports: [MitsNumberBoxComponent],
  providers: [],
})
export class MitsNumberBoxModule {}
