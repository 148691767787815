import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { BufferstockModel, StockObjectModel } from '../models';

export class StockWithInventoryService<
  T extends BufferstockModel | StockObjectModel
> extends OfflineDataService<T> {
  async startInventoryLocal(bufferstockId: number, userId: number) {
    const bs = (await this.localFind(bufferstockId)).content;
    bs.locked = true;
    bs.locked_by_id = userId;
    this.saveLocal(bs);
  }

  async endInventoryLocal(bufferstockId: number) {
    const bs = (await this.localFind(bufferstockId)).content;
    bs.locked = false;
    bs.locked_by_id = undefined;
    this.saveLocal(bs);
  }
}
