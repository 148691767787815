import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { FunctionSwitchModel } from 'src/app/models/settings/function-switch';
import { FunctionSwitchService } from '../model-services/function-switch.service';

@Injectable({
  providedIn: 'root',
})
export class FunctionSwitchHelperService {
  /** All active Function-Switches */
  private onlyActiveFunctionSwitches: FunctionSwitchModel[] = [];
  /** Are all Function-Switches loaded? */
  public _functionSwitchesLoaded = false;

  constructor(private readonly functionSwitchService: FunctionSwitchService) {
    this.functionSwitchService.allWithoutPaginate().subscribe((res) => {
      this.onlyActiveFunctionSwitches = res.data.filter((fs) => {
        return fs.active ? fs : null;
      });
      this._functionSwitchesLoaded = true;
    });
  }

  /**
   * Checks if the current user has the rights for this Function
   * @param functionSwitchKey Key of the FunctionSwitch
   * @returns if the current user has the rights for this Function
   */
  public has(functionSwitchKey: string): boolean {
    return this.onlyActiveFunctionSwitches.findIndex((afs) => {
      return afs.key === functionSwitchKey ? afs : null;
    }) >= 0
      ? true
      : false;
  }

  /**
   * Laden von mehreren Funktionsschaltern
   * @param functionSwitchKeys to load
   * @returns Loaded FunctionSwitches
   */
  public load(
    functionSwitchKeys: Record<string, string>
  ): Record<string, boolean> {
    const result = {};
    Object.keys(functionSwitchKeys || {}).forEach((key) => {
      const name = functionSwitchKeys[key];
      result[key] = this.has(name);
    });
    return result;
  }

  /**
   * Returns the value for this Function if the switch uses its value
   * @param functionSwitchKey Key of the FunctionSwitch
   * @returns the value for this FunctionSwitch
   */
  public async getValue(functionSwitchKey: string): Promise<string> {
    const functionSwitches = await firstValueFrom(
      this.functionSwitchService.allWithoutPaginate()
    );
    const foundFS = functionSwitches.data.find(
      (afs) => afs.key === functionSwitchKey
    );
    if (foundFS?.onlyValue) return foundFS.value;
    return null;
  }
}
