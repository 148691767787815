import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { IonMenu } from '@ionic/angular';
import { KeycloakService } from 'keycloak-angular';
import { filter, map } from 'rxjs';
import { PageService } from 'src/app/providers/page-services/page.service';
import {
  CAR_MANAGEMENT,
  DAMAGE_REPORTS_MACHINE,
  DAMAGE_REPORTS_ORDER,
  LG_VERIFY_ORDER_OF_BUFFERSTOCK,
  SA_CAN_BE_CREATED,
  SO_USE_BUFFERSTOCKS,
  TOUR_MANAGEMENT,
  ZE_ENABLE_CORRECTIONS,
  ZE_END_DAY_ON_CAR_LOGOUT,
  ZE_FOR_USER_OR_CAR,
  ZE_SHOW_TIMETRACKING_IN_MENU,
  ZE_START_DAY_ON_CAR_LOGIN,
} from '../assets/constants/functionSwitch.constants';
import { VERSION } from './../assets/constants/version.js';
import { BusinessModel } from './models/settings/business';
import { MandatorModel } from './models/settings/mandator';
import { ISideMenu } from './models/sidemenu/sidemenu';
import {
  IMenuBasicElement,
  ISideMenuElement,
} from './models/sidemenu/sidemenu-element';
import { InventoriesHelper } from './pages/processing/inventories/inventories-helper';
import { TimelogHelper } from './pages/processing/orders/order-details/helper/timelog-helper';
import { AccessGuard } from './providers/access.guard';
import { FunctionSwitchHelperService } from './providers/component-helpers/function-switch.service';
import { OrdersHelper } from './providers/component-helpers/orders-helper.service';
import { ToastService } from './providers/component-helpers/toast.service';
import { EventsService } from './providers/events.service';
import { ErrorLogHelper } from './providers/helpers/errorlogs-helper.service';
import { SystemHelper } from './providers/helpers/system-helper';
import { UserHelper } from './providers/helpers/user-helper.service';
import { SyncWrapperService } from './providers/syncWrapper.service';
import { InitializationHelper } from './providers/helpers/initialization-helper';

// How often should the badges be reloaded (orders)
const RELOAD_ORDERS_TIMER = 120 * 1000;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  /** Version der App (Jahr*.Kalenderwoche*.Minor) *Zeitpunkt des Sprintstarts */
  readonly version: string = VERSION;
  /** Hier wird das komplette Side-Menu definiert */
  sideMenu: ISideMenu;
  /** Momentan ausgewählte/geöffnete Seite/Page */
  selectedPage: string;
  /** Ausgewählter Mandant */
  selectedMandator: MandatorModel;
  /** Ausgewähltes Business */
  selectedBusiness: BusinessModel;
  /** interval for loading all roles */
  intervalLoadingRoles: any;
  /** Interval for loading errorlogs (interval depends on user-group) */
  intervalErrorlog: any;
  /** Interval for loading orders count */
  intervalReloadOrders: any;
  /** Subscription of the event when the user gets loaded */
  subscriptionUserLoaded: any;
  /** can create Order from SideMenu */
  _canCreateOrderFromSideMenu: boolean;

  @ViewChild('ionicMenu') ionicMenuComponent: IonMenu;

  private appConfigured = false;
  private _usesDamagereportsOnOrders: boolean;
  private _usesDamagereportsOnMachines: boolean;
  private _timetrackingEnabled: boolean;
  private _showTimetrackingInMenu: boolean;
  private _canCorrectTimelogs: boolean;
  private _carManagementActive: boolean;
  private _useBufferstocks: boolean;
  private _tourmanagementEnabled: boolean;
  public _needsVerificationForOrder: boolean;

  constructor(
    public readonly errorLogHelper: ErrorLogHelper,
    public readonly toastService: ToastService,
    public readonly userHelper: UserHelper,
    private readonly accessGuard: AccessGuard,
    private readonly events: EventsService,
    private readonly functionSwitchHelper: FunctionSwitchHelperService,
    private readonly initializationHelper: InitializationHelper,
    private readonly inventoriesHelper: InventoriesHelper,
    private readonly keycloakService: KeycloakService,
    private readonly ordersHelper: OrdersHelper,
    private readonly pageService: PageService,
    private readonly router: Router,
    private readonly swUpdate: SwUpdate,
    private readonly syncWrapperService: SyncWrapperService,
    public readonly systemHelper: SystemHelper,
    private readonly timelogHelper: TimelogHelper
  ) {
    this.swUpdate.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.debug(
            `[AppComponent] Downloading new app version: ${evt.version.hash}`
          );
          break;
        case 'VERSION_READY':
          console.debug(
            '[AppComponent] Update ready from "',
            evt.currentVersion.hash,
            '" to "',
            evt.latestVersion.hash,
            '"'
          );
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.debug(
            `[AppComponent] Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
      }
    });

    const updatesAvailable = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map((evt) => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))
    );

    updatesAvailable.subscribe((evt) => {
      console.debug(
        '[AppComponent] Update available from "',
        evt.current,
        '" to "',
        evt.available,
        '"'
      );
      if (evt.current.hash != evt.available.hash) {
        this.systemHelper.isVersionOutdated = true;
        this.systemHelper.versionDetails = {
          current: evt.current.hash,
          latest: evt.available.hash,
        };
      }
    });

    this.systemHelper.isDeveloper =
      sessionStorage.getItem('developer') === 'true';
    if (window.location.href.includes('http://localhost:')) {
      this.systemHelper.isDeveloper = true;
    }
  }

  ngOnInit() {
    this.buildAndSetAccess();
    this.initializeCurrentPageListener();
    this.systemHelper.initializeNetworkCheck();

    this.subscriptionUserLoaded = this.events
      .subscribe('userHelper:loadedUser')
      .subscribe(() => {
        this.startInitialization();
        if (!this.functionSwitchHelper.has(ZE_START_DAY_ON_CAR_LOGIN))
          this.timelogHelper.tryToStartDay();
        this.subscriptionUserLoaded.unsubscribe();
      });

    this.events.subscribe('logout').subscribe(() => {
      this.logout();
    });

    this.reloadCounterData();
    this.intervalReloadOrders = setInterval(() => {
      this.reloadCounterData();
    }, RELOAD_ORDERS_TIMER);
  }

  ngOnDestroy() {
    this.systemHelper.networkListener.remove();
    clearInterval(this.intervalLoadingRoles);
    clearInterval(this.intervalErrorlog);
    clearInterval(this.intervalReloadOrders);
  }

  private async startInitialization() {
    this.syncWrapperService.startSync();
    await this.initializationHelper.runInitialization();
  }

  /**
   * Eine Page öffnen
   */
  openPage(menuElement: ISideMenuElement): void {
    if (menuElement.onAction) {
      menuElement.onAction();
    }
    if (menuElement.routerLink) {
      if (
        this.pageService.deviceTypes.ios ||
        this.pageService.deviceTypes.android
      ) {
        this.ionicMenuComponent.close();
      }
      this.router.navigateByUrl(menuElement.routerLink);
    }
  }

  /**
   * Sichtbarkeit von Subelementen wechseln
   */
  changeSubElementsVisible(menuElement: ISideMenuElement): void {
    menuElement.subelementsVisible = !menuElement.subelementsVisible;
  }

  /**
   * Compare Methode für die Ion-Selects
   * @param o1 Option 1
   * @param o2 Option 2
   * @returns ob die Optionen gleich sind
   */
  compareWith(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  changedBusiness(): void {
    const success = this.userHelper.setBusiness(this.selectedBusiness);
    if (!success) {
      this.selectedBusiness = this.userHelper.getManBus().business;
    }
  }

  /**
   * Reloads data for the counters and rebuilds side-menu
   */
  async reloadCounterData(): Promise<void> {
    await this.ordersHelper.reloadServiceContracts();
    await this.inventoriesHelper.loadRequiredInventories();
    this.buildSideMenu();
    this.setAccess();
  }

  /**
   * Initializes the listener for new routes to display as current page
   */
  private initializeCurrentPageListener(): void {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe({
        next: (v: NavigationEnd) => {
          this.checkAndSetCurrentPage(v.urlAfterRedirects);
        },
      });
  }

  /**
   * Simplifys the url so no params or domain is present
   * @param url to simplify
   * @returns simplified url-string
   */
  private simplifyUrl(url: string): string {
    if (url.charAt(0) === '/') url = url.substring(1);
    return url.split('?', 1)[0];
  }

  /**
   * Checks and sets for same page url on an element
   * @param e Menu-Element to check
   * @param url to check for inside element
   */
  private checkSamePage(e: IMenuBasicElement, url: string) {
    if (this.checkForURLMatch(url, e)) {
      if (e.name) this.selectedPage = e.name;
    }
  }

  private checkElementForPage(elements: ISideMenuElement[], url: string) {
    elements.forEach((e) => {
      this.checkSamePage(e, url);
      if (e.subElements && e.subElements.length > 0) {
        this.checkElementForPage(e.subElements, url);
      }
    });
  }

  /**
   * Checks if the url is valid and sets the current page correctly
   * @param url URL to set for current page
   * @returns if side was found with this url
   */
  private checkAndSetCurrentPage(url: string): void {
    if (!this.sideMenu || !url) return;
    url = this.simplifyUrl(url);
    this.sideMenu.groups.forEach((g) => {
      this.checkElementForPage(g.elements, url);
    });
  }

  /**
   * Checks if URL is used inside the elements routerLink
   * @param url URL to check
   * @param element element to compare
   * @returns True / False
   */
  private checkForURLMatch(url: string, element: ISideMenuElement): boolean {
    if (!url || !element || !element.routerLink) return false;
    return element.routerLink.includes(url);
  }

  private async configureApp() {
    await this.loadData();
    this._canCreateOrderFromSideMenu =
      this.functionSwitchHelper.has(SA_CAN_BE_CREATED);
    this._usesDamagereportsOnOrders =
      this.functionSwitchHelper.has(DAMAGE_REPORTS_ORDER);
    this._usesDamagereportsOnMachines = this.functionSwitchHelper.has(
      DAMAGE_REPORTS_MACHINE
    );
    this._timetrackingEnabled =
      this.functionSwitchHelper.has(ZE_FOR_USER_OR_CAR);
    this._showTimetrackingInMenu = this.functionSwitchHelper.has(
      ZE_SHOW_TIMETRACKING_IN_MENU
    );
    this._canCorrectTimelogs = this.functionSwitchHelper.has(
      ZE_ENABLE_CORRECTIONS
    );
    this._carManagementActive = this.functionSwitchHelper.has(CAR_MANAGEMENT);
    this._useBufferstocks = this.functionSwitchHelper.has(SO_USE_BUFFERSTOCKS);
    this._needsVerificationForOrder = this.functionSwitchHelper.has(
      LG_VERIFY_ORDER_OF_BUFFERSTOCK
    );
    this._tourmanagementEnabled =
      this.functionSwitchHelper.has(TOUR_MANAGEMENT);

    if (this.userHelper.getUser()?.is_admin) {
      if (!this.intervalErrorlog)
        this.intervalErrorlog = setInterval(() => {
          this.errorLogHelper.reloadErrorlogs();
        }, 60000);
      else this.errorLogHelper.reloadErrorlogs();
    }
    this.buildSideMenu();
    this.setAccess();
    this.checkAndSetCurrentPage(this.router.url);
    this.appConfigured = true;
  }

  /**
   * Baut das Seitenmenü und setzt wenn möglich die Rechte
   */
  private async buildAndSetAccess(): Promise<void> {
    if (this.appConfigured) {
      await this.configureApp();
    } else {
      this.intervalLoadingRoles = setInterval(async () => {
        if (
          this.accessGuard.areRolesLoaded() &&
          this.functionSwitchHelper._functionSwitchesLoaded
        ) {
          await this.configureApp();
          this.ordersHelper.reloadServiceContracts();
          clearInterval(this.intervalLoadingRoles);
        }
      }, 2 * 1000);
    }
  }

  /**
   * Lädt alle für das Side-Menu wichtigen Daten
   * Wählt wenn vorhanden schon einen Mandanten und eine Betriebsstätte
   */
  private async loadData(): Promise<void> {
    const resUser = await this.userHelper.loadUser();
    this.selectedMandator = resUser.mandator;
    if (resUser.businesses?.length > 0) {
      this.selectedBusiness = resUser.businesses[0];
    }
  }

  /**
   * Funktion um den aktuellen Benutzer auszuloggen
   */
  private async logout(): Promise<void> {
    if (!this.functionSwitchHelper.has(ZE_END_DAY_ON_CAR_LOGOUT))
      await this.timelogHelper.tryToEndDay();
    this.userHelper.logoutFull();
    this.keycloakService.logout(window.location.origin);
  }

  /**
   * Menü aufbauen
   */
  private buildSideMenu(): void {
    this.sideMenu = {
      headerTitle: 'Hauptmenü',
      groups: [
        {
          title: '',
          elements: [
            {
              title: 'Startseite',
              icon: 'home-outline',
              routerLink: 'dashboard-worker',
              name: 'dashboard-worker',
              offlineSupported: true,
              accessGranted: false,
            },
          ],
        },
        {
          title: 'Bearbeitung',
          elements: [
            {
              title: 'Schnelleinstieg',
              icon: 'scan',
              routerLink: 'quick-start',
              name: 'fast-entrance',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: this.userHelper.isFiller
                ? 'Aktive Befüllungen'
                : 'Aktive Aufträge',
              icon: 'document-text-outline',
              routerLink: 'orders/active',
              name: 'orders-active',
              offlineSupported: true,
              accessGranted: false,
              badge: {
                color: 'primary',
                text: this.ordersHelper.getCounters().activeCount,
              },
            },
            {
              title: this.userHelper.isFiller
                ? 'Geplante Tour'
                : 'Serviceaufträge',
              icon: 'document-text-outline',
              routerLink: 'orders/overview',
              name: 'orders-overview',
              offlineSupported: true,
              accessGranted: false,
              badge: {
                color: 'primary',
                text: this.ordersHelper.getCounters().openCount,
              },
            },
            {
              title: 'Aktive Inventuren',
              icon: 'newspaper-outline',
              routerLink: 'inventories/active',
              name: 'inventories-active',
              offlineSupported: false,
              accessGranted: false,
              badge: {
                color: 'primary',
                text: this.inventoriesHelper.getCounters().activeCount,
              },
              hidden: !this._useBufferstocks,
            },
            {
              title: 'Auftrag erstellen',
              icon: 'document-attach-outline',
              routerLink: 'emergency-order/create',
              name: 'order-create-emergency',
              offlineSupported: true,
              accessGranted: false,
              hidden: !this._canCreateOrderFromSideMenu,
            },
          ],
        },
        {
          title: 'Stammdaten',
          elements: [
            {
              title: 'Kunden',
              icon: 'people-outline',
              routerLink: 'customers',
              name: 'customers',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: 'Maschinen',
              icon: 'cafe-outline',
              routerLink: 'machines',
              name: 'machines',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: 'Autos',
              icon: 'car-sport-outline',
              routerLink: 'cars',
              name: 'cars',
              offlineSupported: true,
              accessGranted: false,
              hidden: !this._carManagementActive,
            },
            {
              title: 'Zeiterfassung',
              icon: 'stopwatch-outline',
              routerLink: 'timelogs',
              name: 'timelogs',
              offlineSupported: true,
              accessGranted: false,
              hidden:
                !this._timetrackingEnabled || !this._showTimetrackingInMenu,
            },
            {
              title: 'Schadensmeldungen',
              icon: 'trash-bin-outline',
              routerLink: 'damageManagement/damage_reports',
              name: 'damage_reports',
              offlineSupported: true,
              accessGranted: false,
              hidden: !(
                this._usesDamagereportsOnMachines ||
                this._usesDamagereportsOnOrders
              ),
            },
          ],
        },
        {
          title: 'Verwaltung',
          hidden: !(this.userHelper.isInside || this.userHelper.isAdmin),
          elements: [
            {
              title: 'Dashboard',
              icon: 'apps-outline',
              routerLink: 'dashboard-inside',
              name: 'dashboard-inside',
              accessGranted: false,
            },
            {
              title: 'Serviceaufträge',
              icon: 'document-text-outline',
              routerLink: 'service_contracts',
              name: 'service-contracts',
              accessGranted: false,
            },
            {
              title: 'Korrekturanfragen',
              icon: 'stopwatch-outline',
              routerLink: 'timelog-corrections',
              name: 'timelog-corrections',
              hidden: !(this._timetrackingEnabled && this._canCorrectTimelogs),
              accessGranted: false,
            },
            {
              title: 'Bestellanfragen',
              icon: 'cart-outline',
              routerLink: 'warehouse/orders',
              name: 'warehouse-orders',
              hidden:
                !this._useBufferstocks || !this._needsVerificationForOrder,
              accessGranted: false,
            },
            {
              title: 'Einstellungen',
              icon: 'cog-outline',
              routerLink: 'settings',
              name: 'settings',
              accessGranted: false,
            },
            {
              title: 'Tourenplanung',
              icon: 'map-outline',
              routerLink: 'tour-management',
              name: 'tour-management',
              hidden: !this._tourmanagementEnabled,
              accessGranted: false,
            },
          ],
        },
        {
          title: 'Optionen',
          cssClass: 'bottom',
          elements: [
            {
              title: 'Hilfe',
              icon: 'help-outline',
              routerLink: 'help',
              name: 'help',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: 'Abmelden',
              icon: 'log-out-outline',
              color: 'danger',
              offlineSupported: true,
              accessGranted: true,
              onAction: () => {
                this.logout();
              },
            },
          ],
        },
      ],
    };
  }

  /**
   * Setzt Berechtigungen im Menü
   */
  private async setAccess(): Promise<void> {
    this.sideMenu.groups.forEach(async (group) => {
      group.elements.forEach(async (element) => {
        let hasRoles =
          element.rolesNeeded && element.rolesNeeded.length > 0
            ? element.rolesNeeded
                .map((role) => this.accessGuard.hasRight(role))
                .every((r) => r)
            : true;
        let hasAccessToRoute = await this.accessGuard.hasAccessToPath(
          element.routerLink
        );
        element.accessGranted = hasRoles && hasAccessToRoute;
        if (element.accessGranted) {
          group.accessGranted = true;
        }
      });
    });
  }
}
