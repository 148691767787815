// tslint:disable: no-redundant-jsdoc
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncError } from '@vending/sync-engine-client/dist/sync-engine-client';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { GlobalHelper } from 'src/packages/mitsBasics/helpers/globalHelper/global.helper';
import { EndpointService } from 'src/packages/mitsBasics/providers/endpoint.service';
import { BasicModel, OptionalBasicModel } from '../models/basic';
import { ResponseModel } from './../models/response';
import { ErrorService } from './error.service';
import { IDataService } from './idataService';

@Injectable({
  providedIn: 'root',
})
export class DataService<T extends BasicModel | OptionalBasicModel>
  extends EndpointService
  implements IDataService<T>
{
  public currentlySaving = false;

  public removeParams = ['created_at', 'updated_at'];
  public attributedParams: string[] = [];

  /**
   * HTTP Options
   */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  /**
   * Default-Constructor
   * @param {HttpClient} http
   * @param {ErrorService} errorService
   */
  constructor(public http: HttpClient, public errorService: ErrorService) {
    super();
  }

  allRemote(): Observable<ResponseModel<T>> {
    return this.http
      .get<ResponseModel<T>>(this.endpointWithUrl)
      .pipe(retry(1), catchError(this.errorService.convert));
  }
  findRemote(id: number): Observable<T> {
    return this.http
      .get<T>(this.endpointWithUrl + id)
      .pipe(retry(1), catchError(this.errorService.convert));
  }
  whereRemote(args: any): Observable<ResponseModel<T>> {
    return this.http
      .get<ResponseModel<T>>(this.endpointWithUrl, { params: args })
      .pipe(retry(1), catchError(this.errorService.convert));
  }
  deleteRemote(id: number): Observable<T> {
    return this.delete(id);
  }

  /**
   * Objekt tief clonen. Ist nicht sehr performance orientiert, aber am simpelsten
   * @param {any} object
   * @return {any}
   */
  public cloneObjekt(object: any): any {
    return GlobalHelper.deepClone(object);
  }

  /**
   * Entfernt Parameter welche nicht gesendet werden sollen
   * @param hash to remove params of
   */
  removeNotToUseParams(hash: any) {
    // Neues Objekt erstellen
    if (hash === undefined || hash == null) return;
    this.removeParams.forEach((p) => {
      delete hash[p];
    });
    Object.keys(hash).forEach((element) => {
      if (typeof hash[element] === 'object')
        this.removeNotToUseParams(hash[element]);
    });
  }

  /**
   * Remove not used attributes
   * @param {any} hash
   */
  renameAttributedParams(hash: any) {
    if (hash === undefined || hash == null) return;
    for (const k of Object.keys(hash)) {
      if (typeof hash[k] === 'object') {
        this.renameAttributedParams(hash[k]);
      }
      if (this.attributedParams.includes(k)) {
        const v = hash[k];
        delete hash[k];
        hash[k + '_attributes'] = v;
      }
    }
    return hash;
  }

  /**
   * Erhält alle Objecte vom Endpunkt.
   */
  all(): Observable<ResponseModel<T>> {
    return this.http
      .get<ResponseModel<T>>(this.endpointWithUrl)
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Ruft ein Objekt mit Details ab
   * @param id
   */
  find(id: number): Observable<any> {
    return this.http
      .get<any>(this.endpointWithUrl + id)
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Sucht Objekte mit Argumenten
   * @param args
   */
  where(args): Observable<ResponseModel<T>> {
    return this.http
      .get<ResponseModel<T>>(this.endpointWithUrl, { params: args })
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Erzeugt/Speichert ein Objekt im Backend (je nachdem ob ID gesetzt)
   * @param object Zu speicherndes/erzeugendes Objekt
   */
  save(object: any): Observable<T> {
    if (object.id && object.id >= 0) {
      return this.update(object.id, object);
    } else {
      return this.create(object);
    }
  }

  /**
   * Erzeugt ein neues Objekt im Backend
   * @param object
   */
  private create(object: any): Observable<T> {
    delete object.id;
    return this.http
      .post<T>(
        this.endpointWithUrl,
        this.formatPayload(object),
        this.httpOptions
      )
      .pipe(catchError(this.errorService.convert));
  }

  /**
   * Aktualisiert ein Objekt
   * @param id
   * @param object
   */
  private update(id: number, object: any): Observable<T> {
    return this.http
      .put<T>(
        this.endpointWithUrl + id,
        this.formatPayload(object),
        this.httpOptions
      )
      .pipe(catchError(this.errorService.convert));
  }

  /**
   * Prüft ob es ein Offline Sync Problem gibt.
   * @param id
   */
  hasError(id: number): Promise<SyncError> {
    return Promise.resolve(new SyncError(200, {}));
  }

  /**
   * Löscht ein Objekt im Backend
   * @param id
   */
  delete(id: number): Observable<T> {
    return this.http
      .delete<T>(this.endpointWithUrl + id)
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  formatPayload(obj: T) {
    const hashToSend = {};
    hashToSend[this.objectName] = this.cloneObjekt(obj);
    this.removeNotToUseParams(hashToSend);
    this.renameAttributedParams(hashToSend);
    return JSON.stringify(hashToSend);
  }
}
