import { Injectable } from '@angular/core';
import { OrderModel } from 'src/app/models/order';
import { ErrorService } from 'src/app/providers/error.service';
import { OrderService } from '../model-services/orders/order.service';

export interface ServiceContractCounters {
  activeCount: string;
  openCount: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrdersHelper {
  /** Array of all service-contracts */
  private openOrders: OrderModel[] = [];
  /** Array of all active-contracts */
  private activeOrders: OrderModel[] = [];
  /** Count of active-contracts */
  private activeOrdersCount = 0;
  /** Count of service-contracts */
  private serviceOrdersCount = 0;

  constructor(
    private readonly errorService: ErrorService,
    private readonly orderService: OrderService
  ) {}

  /**
   * Reloads the serviceContracts and sets the counters
   * @returns both counters inside a promise
   */
  public async reloadServiceContracts(): Promise<{
    activeResult: number;
    serviceResult: number;
  }> {
    return {
      activeResult: await this.loadActiveContracts(),
      serviceResult: await this.loadServiceContracts(),
    };
  }

  /**
   * Get the current counts
   * @returns count of active and service-contracts
   */
  public getCounters(): ServiceContractCounters {
    return {
      activeCount: this.activeOrdersCount.toString(),
      openCount: this.serviceOrdersCount.toString(),
    };
  }

  /**
   * Loads all service-contracts and gets count
   */
  private async loadServiceContracts(): Promise<number> {
    return new Promise((res, rej) => {
      this.orderService.forUser({ orders_filter: 'open' }).subscribe(
        (result) => {
          this.openOrders = result;
          this.serviceOrdersCount = this.openOrders?.length || 0;
          res(this.serviceOrdersCount);
        },
        (err) => {
          this.errorService.handle(err);
          rej(err);
        }
      );
    });
  }

  /**
   * Loads all service-contracts with order_filter active and gets count
   */
  private async loadActiveContracts(): Promise<number> {
    return new Promise((res, rej) => {
      this.orderService.forUser({ orders_filter: 'active' }).subscribe(
        (result) => {
          this.activeOrders = result;
          this.activeOrdersCount = this.activeOrders?.length || 0;
          res(this.activeOrdersCount);
        },
        (err) => {
          this.errorService.handle(err);
          rej(err);
        }
      );
    });
  }
}
