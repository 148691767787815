import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { BufferstockInventoryModel } from '../models/bufferstock-inventory';

@Injectable({
  providedIn: 'root',
})
export class BufferstockInventoryService extends OfflineDataService<BufferstockInventoryModel> {
  /**
   * HTTP Options
   */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Warehouse::Inventur',
      http,
      errorService,
      events,
      'warehouse/inventurs/',
      'warehouse_inventur',
      [],
      ['positions']
    );
  }

  /**
   * post-request to start the inventory
   * @param stock_object_id ID of stock inventory is started for
   * @param userId ID of current user
   * @returns inventory
   */
  startInventory(
    stock_object_id: string,
    userId: string
  ): Observable<BufferstockInventoryModel> {
    const params = new HttpParams()
      .set('user_id', userId)
      .set('stock_object_id', stock_object_id);
    return this.http
      .post<any>(this.endpointWithUrl + 'start_inventur', {}, { params })
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * post-request to end the inventory
   * @param stock_object_id ID of stock inventory is stopped for
   * @returns inventory
   */
  endInventory(stock_object_id: string): Observable<BufferstockInventoryModel> {
    const params = new HttpParams().set('stock_object_id', stock_object_id);
    return this.http
      .post<any>(this.endpointWithUrl + 'end_inventur', {}, { params })
      .pipe(retry(1), catchError(this.errorService.convert));
  }
}
