import { Injectable } from '@angular/core';
import { PluginListenerHandle } from '@capacitor/core';
import { Network } from '@capacitor/network';

export type EASTER_EGG = 'christmas';

@Injectable({
  providedIn: 'root',
})
export class SystemHelper {
  networkListener: PluginListenerHandle;

  isOnline: boolean = false;
  isDeveloper: boolean = false;
  isDownloadRunning: boolean = false;
  isBackendOnline: boolean = true;
  isVersionOutdated: boolean = false;
  versionDetails: { current: string; latest: string };

  currentEvent?: EASTER_EGG = null;

  constructor() {
    this.updateCurrentEvent();
  }

  initializeNetworkCheck() {
    Network.getStatus().then((status) => {
      this.isOnline = status.connected;
    });
    this.networkListener = Network.addListener(
      'networkStatusChange',
      (status) => {
        this.isOnline = status.connected;
      }
    );
  }

  /**
   * Copies the given text to the clipboard
   * @param text to copy
   */
  copyToClipboard(text: string) {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', false, text);
    }
  }

  private updateCurrentEvent() {
    const now = new Date();
    if (now.getMonth() === 11) this.currentEvent = 'christmas';
  }
}
