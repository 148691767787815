import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { UserModel } from 'src/app/models/users/user';
import { ErrorService } from '../error.service';
import { EventsService } from '../events.service';
import { OfflineDataService } from '../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends OfflineDataService<UserModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'User',
      http,
      errorService,
      events,
      'users/',
      'user',
      ['created_at', 'updated_at', 'created_by_id', 'mandator', 'mandator_id'],
      []
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return name of icon as string
   */
  public get iconName(): string {
    return 'person-outline';
  }

  /**
   * gets current user, that is signed in
   * @returns get request for signed in user
   */
  current_user(): Observable<UserModel> {
    return this.http
      .get<UserModel>(this.endpointWithUrl + 'current')
      .pipe(retry(1));
  }

  inside(): Observable<UserModel[]> {
    return this.localAll().pipe(
      map((res) => {
        return res.filter((u) => {
          if (u.groups)
            return (
              u.groups.findIndex((g) => g.group_name === 'Innendienst') >= 0
            );
        });
      })
    );
  }

  outside(): Observable<UserModel[]> {
    return this.localAll().pipe(
      map((res) => {
        return res.filter((u) => {
          if (u.groups)
            return (
              u.groups.findIndex((g) => g.group_name === 'Aussendienst') >= 0
            );
        });
      })
    );
  }
}
