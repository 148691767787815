import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StockBooking } from '../models';
import { StockInventoryHelperService } from '../helpers/stock-inventory-helper';

@Injectable({
  providedIn: 'root',
})
export class StockBookingService {
  constructor(public readonly stockHelper: StockInventoryHelperService) {}

  /**
   * Verbuch ein StockBooking als einzelne Movements
   * @param {StockBooking} stockBooking
   * @return {Observable<number>}
   */
  public save(stockBooking: StockBooking): Observable<number> {
    const dataToSend = stockBooking.buildStockMovements();
    return new Observable<number>((observer) => {
      let counter = 0;
      dataToSend.forEach((movement) => {
        this.stockHelper.book(movement).then(
          (res) => {
            counter++;
            observer.next((counter / dataToSend.length) * 1.0);
            if (counter === dataToSend.length) {
              observer.complete();
            }
          },
          (error) => {
            observer.error(error);
          }
        );
      });
    });
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'file-tray-full-outline';
  }
}
