import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable, retry } from 'rxjs';
import { ResponseModel } from 'src/app/models/response';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { IChecklist } from '../models/checklist';

const TRANSLATION_TYPES = {
  Machine: 'Maschine',
  Order: 'Serviceauftrag',
  Hygiene: 'Hygiene',
  WorkingStep: 'Arbeitsschritt',
};

const NESTED_ATTRIBUTE_KEYS = ['groups', 'modules', 'dep'];

@Injectable({
  providedIn: 'root',
})
export class ChecklistService extends OfflineDataService<IChecklist> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'ChecklistManagement::Checklist',
      http,
      errorService,
      events,
      'checklist_management/checklists/',
      'checklist',
      ['created_at', 'updated_at', 'created_by_id'],
      []
    );
  }

  /**
   * Translates a specific type
   * @param type to translate
   * @return translated string
   */
  public static translateType(type: string): string {
    return TRANSLATION_TYPES[type];
  }

  /**
   * Gets all translations
   * @return all translations
   */
  public static get translations(): Record<string, string> {
    return TRANSLATION_TYPES;
  }

  /**
   * overload and streamline object before save
   * @param object checklist to save
   * @return oberveable of save
   */
  public override save(object: IChecklist): Observable<IChecklist> {
    const mappedObject = this.mapAndFilterObject(object);
    return super.save(mappedObject);
  }

  /**
   * overload and streamline object before save
   * @param object checklist to save
   * @return oberveable of save
   */
  public override saveRemote(object: IChecklist): Observable<IChecklist> {
    const mappedObject = this.mapAndFilterObject(object);
    return super.saveRemote(mappedObject);
  }

  /**
   * Streamline Checklist
   * @param obj checklist to streamline
   * @return streamlined checklist
   */
  private mapAndFilterObject(obj: IChecklist): IChecklist {
    if (!obj) {
      return obj;
    }
    for (const k of Object.keys(obj)) {
      if (typeof obj[k] === 'object') {
        this.mapAndFilterObject(obj[k]);
      }
      if (NESTED_ATTRIBUTE_KEYS.includes(k)) {
        const v = obj[k];
        delete obj[k];
        obj[k + '_attributes'] = v;
      }
    }
    return obj;
  }

  /**
   * Search for checklists with a specific resource
   * @param name is the name of the resource the checklists should have
   * @returns checklists with resource === name
   */
  resource(name: string): Observable<ResponseModel<IChecklist>> {
    return this.http
      .get<ResponseModel<IChecklist>>(
        this.endpointWithUrl + 'resource?resource=' + name
      )
      .pipe(retry(1));
  }

  cachedChecklists: IChecklist[] | undefined = undefined;
  /**
   * Search for checklists with a specific resource
   * @param name is the name of the resource the checklists should have
   * @returns checklists with resource === name
   */
  async resourceLocal(name: string): Promise<IChecklist[]> {
    if (!this.cachedChecklists) {
      this.cachedChecklists = await this.localWhereWithoutPaging({});
    }

    return this.cachedChecklists.filter(
      (checklist) => checklist.resource === name
    );
  }
}
