import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { WarehouseOrderModel } from '../models/warehouse-order';
import { Observable } from 'rxjs';
import { de } from 'date-fns/locale';

@Injectable({
  providedIn: 'root',
})
export class WarehouseOrderService extends OfflineDataService<WarehouseOrderModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Warehouse::Order',
      http,
      errorService,
      events,
      'warehouse/orders/',
      'warehouse_order',
      [],
      ['positions', 'bufferstock', 'basket']
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'document-attach-outline';
  }

  protected cloneObjekt(object: WarehouseOrderModel): WarehouseOrderModel {
    // Entfernt die Verknüpfung zu einem Lagerobjekt um eine circular structure zu verhindern
    if (object.linked_to?.stock_object) {
      delete (object.linked_to.stock_object as any).linked_to;
    }
    return JSON.parse(JSON.stringify(object));
  }

  /**
   * Erzeugt/Speichert ein Objekt im Backend (je nachdem ob ID gesetzt)
   * @param object Zu speicherndes/erzeugendes Objekt
   */
  override saveRemote(
    object: WarehouseOrderModel
  ): Observable<WarehouseOrderModel> {
    let cloned = Object.assign({}, object);
    delete cloned.bufferstock;
    delete cloned.basket;
    delete cloned.linked_to;
    if (object.position_disposals)
      cloned.position_disposals = object.position_disposals.map((pos) => {
        let clonedPos = Object.assign({}, pos);
        delete clonedPos.bufferstock;
        return clonedPos;
      });
    if (object.positions)
      cloned.positions = object.positions.map((pos) => {
        let clonedPos = Object.assign({}, pos);
        return clonedPos;
      });
    return this.dataService.save(object);
  }
}
