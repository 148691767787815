import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { StockObjectModel } from '../models/stock-object';
import { StockWithInventoryService } from './StockWithInventory.service';

@Injectable({
  providedIn: 'root',
})
export class StockObjectService extends StockWithInventoryService<StockObjectModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Warehouse::Stock',
      http,
      errorService,
      events,
      'warehouse/stocks/',
      'warehouse_stock',
      [],
      ['positions']
    );
  }
}
