import { MitsIconModule } from './../mits-icon/mits-icon.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { MitsCheckButtonComponent } from './mits-check-button.component';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule, MitsIconModule],
  declarations: [MitsCheckButtonComponent],
  entryComponents: [MitsCheckButtonComponent],
  exports: [MitsCheckButtonComponent],
  providers: [],
})
export class MitsCheckButtonModule {}
