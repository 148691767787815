import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { PageService } from 'src/app/providers/page-services/page.service';

type PossibleColors = 'primary' | 'debug';

@Component({
  selector: 'mits-header',
  templateUrl: './mits-header.component.html',
  styleUrls: ['./mits-header.component.scss'],
})
export class MitsHeaderComponent implements OnInit {
  /** Title of the header */
  @Input() title: string;
  /** FLAG: Is the header in a modal? */
  @Input() color: PossibleColors = 'primary';
  /** FLAG: Is the header in a modal? */
  @Input() modal? = false;
  /** Default path to go to previous pages */
  @Input() defaultHref?: string;
  /** FLAG: Is the back-button needed? */
  @Input() backButton? = true;
  /** FLAG: Should "this.location.back used instead of defaultHref" */
  @Input() useLocationBack? = false;
  /** FLAG: Should this route be used for navigating back? */
  @Input() onlyDefaultHref? = false;
  /** FLAG: Should the closing be allowed? */
  @Input() allowClose? = true;

  @Output() closed: EventEmitter<any> = new EventEmitter();

  constructor(
    public readonly pageService: PageService,
    private readonly modalCtrl: ModalController,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    if (!this.router.getCurrentNavigation()) return;
    const state = this.router.getCurrentNavigation().extras.state;
    if (state && state.navBack) this.defaultHref = state.navBack;
  }

  /**
   * routes back with route of defaultHref
   */
  routeBack() {
    this.router.navigate([this.defaultHref]);
  }

  /**
   * Closes the modal
   */
  closeModal() {
    if (!this.allowClose) return;
    if (this.closed.observed) {
      this.closed.emit();
    } else {
      this.modalCtrl.dismiss();
    }
  }
}
