import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { TimelogCorrectionModel } from 'src/app/models/system/timelog-correction';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';
import { OfflineDataService } from '../../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class TimelogCorrectionService extends OfflineDataService<TimelogCorrectionModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'System::TimelogCorrection',
      http,
      errorService,
      events,
      'system/timelog_corrections/',
      'system_timelog_correction',
      ['created_at', 'updated_at', 'remote_id'],
      [],
      null,
      true
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'hourglass-outline';
  }
}
