import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { ObjectImageService } from 'src/app/providers/model-services/system';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { WarehouseOrderAcceptanceModel } from '../models/warehouse-order';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WarehouseOrderAcceptanceService extends OfflineDataService<WarehouseOrderAcceptanceModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService,
    public objectImageService: ObjectImageService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Warehouse::OrderAcceptance',
      http,
      errorService,
      events,
      'warehouse/order_acceptances/',
      'warehouse_order_acceptance',
      ['created_at, updated_at'],
      ['positions', 'bufferstock', 'basket', 'created_by']
    );
  }

  /**
   * Bilder versenden aus den lokalen Daten
   * @param order
   */
  public async updateRemoteImages(
    order: WarehouseOrderAcceptanceModel
  ): Promise<any> {
    await this.objectImageService.sendImages(
      order,
      'images',
      'images_remote_ids'
    );
  }


  /**
   * Erzeugt/Speichert ein Objekt im Backend (je nachdem ob ID gesetzt)
   * @param object Zu speicherndes/erzeugendes Objekt
   */
  override saveRemote(object: WarehouseOrderAcceptanceModel): Observable<WarehouseOrderAcceptanceModel> {
    let cloned = Object.assign({}, object);
    delete cloned.bufferstock;
    delete cloned.basket;
    delete cloned.linked_to;
    return this.dataService.save(cloned);
  }


  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'document-lock-outline';
  }
}
