import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { FeatureTypeModel } from '../../../models/features/feature_type';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';
import { OfflineDataService } from '../../offlineData.service';

/**
 * Marks a service class as being eligible for creation by the injector.
 * When provided in the root injector, this service will be available
 * throughout the application.
 * This decorator is used to register a service as a provider for the root application injector.
 * By default, services registered with this decorator will be singletons.
 */
@Injectable({
  providedIn: 'root',
})

/**
 * Service for communication with the feature type endpoint at the backend side.
 * @description
 * A service for managing feature types. Extends the OfflineDataService class for storing feature types offline.
 * @author Johannes Belaschow
 */
export class FeatureTypeService extends OfflineDataService<FeatureTypeModel> {
  /**
   * Creates an instance of FeatureTypeService.
   * @param indexedDBService - The service for accessing IndexedDB.
   * @param syncProcessor - The service for syncing offline data with the server.
   * @param http - The Angular HttpClient for making HTTP requests.
   * @param errorService - The service for handling errors.
   * @param events - The service for handling events.
   */
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Features::FeatureType',
      http,
      errorService,
      events,
      'feature_types/' /** The endpoint for feature types API (backend). */,
      'feature_type' /** The name of the object in JSON file for feature types. */,
      [
        'created_at',
        'updated_at',
        'created_by_id',
      ] /** An array of removeParams for DataService superclass to exclude fields while updating the object. */,
      [] /** An array of attributedParams for DataService superclass to include nested objects. */
    );
  }
}
