import { firstValueFrom } from 'rxjs';
import { ArticleModel } from 'src/app/models/articles/article';
import { PositionModel } from 'src/app/models/position';
import { UserHelper } from 'src/app/providers/helpers/user-helper.service';
import { ArticleService } from 'src/app/providers/model-services/article.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { GlobalHelper } from 'src/packages/mitsBasics/helpers/globalHelper/global.helper';
import {
  BasicInventoryModel,
  IInventoryHelper,
  INVENTORY_HELPER_TYPE,
} from 'src/packages/mitsBasics/interfaces';
import { StocksNotSetError } from '../exceptions';
import { BufferstockModel, StockObjectModel } from '../models';
import { BufferstockInventoryModel } from '../models/bufferstock-inventory';
import {
  StockPositionDisposalModel,
  StockPositionModel,
} from '../models/position';
import { StockMovementModel } from '../models/stockMovement';
import { StockWithInventoryService } from '../providers/StockWithInventory.service';
import { BufferstockInventoryService } from '../providers/buffertock-inventory.service';
import { StockMovementService } from '../providers/stockMovement.service';
import { StockObjectService } from '../providers/stockObject.service';
import { instanceOfSelectButtonArticle } from 'src/app/components/mits-inventory/models/article-with-select-button';

export class BasicStockInventoryHelperService<
  T extends BufferstockModel | StockObjectModel
> implements IInventoryHelper
{
  constructor(
    protected readonly articleService: ArticleService,
    protected readonly bufferstockInventoryService: BufferstockInventoryService,
    protected readonly stockService: StockWithInventoryService<T>,
    protected readonly stockMovementService: StockMovementService,
    protected readonly stockObjService: StockObjectService,
    protected readonly userHelper: UserHelper
  ) {}

  public bufferstock: T;
  public machineStock: StockObjectModel;

  /**
   * Stock setzen Anhand einer Bufferstock ID
   * @async
   * @param {number} id
   */
  async setBufferStockById(id: number) {
    try {
      if (GlobalHelper.isZero(id)) {
        this.bufferstock = undefined;
      } else {
        this.bufferstock = await this.findStockObjectById(
          this.stockService,
          id
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Positionen mit Nullmengen zurückgeben
   * @returns
   */
  public async getZeroAmountPositions(
    inventory: BasicInventoryModel
  ): Promise<StockPositionModel[]> {
    if (!this.bufferstock.positions) return [];
    return this.bufferstock.positions.filter((currPosition) => {
      const pos = inventory.positions?.find((invPosition) =>
        this.compareArticlesWithId(invPosition, currPosition)
      );
      return !pos || pos.amount === 0;
    });
  }

  /**
   * Compares two articles with article_id or select_button_key_name
   * @param objA to compare
   * @param objB to compare
   * @returns if the articles are the same
   */
  private compareArticlesWithId(
    objA: { article_id?: number; select_button_key_name?: string },
    objB: { article_id?: number; select_button_key_name?: string }
  ): boolean {
    if (instanceOfSelectButtonArticle(objA))
      return objA.select_button_key_name === objB.select_button_key_name;
    return objA.article_id === objB.article_id;
  }

  /**
   * StockObject local oder vom Server laden
   * @param id
   * @returns
   */
  private async findStockObjectById<T>(
    service: OfflineDataService<T>,
    id: number
  ): Promise<T> {
    try {
      let foundObj = await service.localFind(id);
      if (foundObj) {
        return foundObj.content;
      } else {
        console.warn(`StockObject with id ${id} not found locally`);
        let stock = await firstValueFrom(service.findRemote(id));
        if (stock) {
          await service.saveLocalForce(stock);
          return stock;
        } else {
          console.error(`StockObject with id ${id} not found remotely`);
          return undefined;
        }
      }
    } catch (ex) {
      console.error(ex);
      return undefined;
    }
  }

  /**
   * Stock setzen Anhand einer Bufferstock ID
   * @async
   * @param {number} id
   */
  async setMachineStockById(id: number) {
    try {
      if (GlobalHelper.isZero(id)) {
        this.machineStock = undefined;
      } else {
        this.machineStock = await this.findStockObjectById(
          this.stockObjService,
          id
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Typen des Helpers laden
   * @return {INVENTORY_HELPER_TYPE}
   */
  getType(): INVENTORY_HELPER_TYPE {
    return 'warehouse';
  }

  /**
   * Bulk Inventarverbuchung für eine Combined Postion
   * @param {number} articleId
   * @param {number} amount
   * @param {number} takeBack
   * @param {number} disposalAmount
   * @return {Promise<boolean|void>}
   */
  async updateInventoryBulk(
    articleId: number,
    amount: number,
    takeBack: number,
    disposalAmount: number
  ): Promise<boolean | void> {
    const article = await this.loadArticle(articleId);
    await this.updateInventory(articleId, amount);
    await this.updateInventory(articleId, takeBack * -1);

    if (!GlobalHelper.isZero(disposalAmount)) {
      await this.bookDisposalOnBufferstock(article, disposalAmount);
    }
  }

  /**
   * Setzt Bufferstock (Quelle) und Maschinenstock (Ziel)
   * Erwartet werden die Ids der StockObjekte
   * @param {number} sourceId
   * @param {number} targetId
   */
  async setSourceAndTarget(sourceId: number, targetId: number) {
    await this.setBufferStockById(sourceId);
    await this.setMachineStockById(targetId);
  }

  /**
   * Verbuchungen vom normalen Lagerbestand
   * @param {number} articleId
   * @param {number} amount
   */
  async updateInventory(
    articleId: number,
    amount: number
  ): Promise<boolean | void> {
    if (amount === 0) {
      return true;
    } else if (this.bufferstock && this.machineStock) {
      let result = true;
      result = result && !!(await this.updateBufferstock(articleId, amount));
      result = result && !!(await this.updateMachineStock(articleId, amount));
      const stockMovement = await this.persistStockMovement(articleId, amount);
      return !!result && !!stockMovement;
    } else {
      console.warn(new StocksNotSetError());
    }
  }

  async startInventur(): Promise<boolean | void> {
    if (!this.bufferstock) return;
    const user = this.userHelper.getUser();
    if (!this.bufferstock.locked) {
      await firstValueFrom(
        this.bufferstockInventoryService.startInventory(
          this.bufferstock.id.toString(),
          user.id.toString()
        )
      ).then(async () => {
        this.stockService.startInventoryLocal(this.bufferstock.id, user.id);
      });
    }
    return (
      (this.bufferstock.locked &&
        this.userHelper.getUser().id === this.bufferstock.locked_by_id) ||
      !this.bufferstock.locked
    );
  }

  async endInventur(
    inventur?: BufferstockInventoryModel
  ): Promise<boolean | void> {
    if (inventur) {
      const newInventory = await firstValueFrom(
        this.bufferstockInventoryService.saveRemote(inventur)
      ).catch((err) => {
        console.error(err);
      });
      if (!newInventory) return;
      const zeroPositions = this.bufferstock.positions.filter(
        (ssp) =>
          !newInventory.positions?.find(
            (nip) => nip.article_id === ssp.article_id
          )
      );
      this.bufferstock.positions = GlobalHelper.deepClone(
        newInventory.positions
      );
      this.bufferstock.positions.push(
        ...zeroPositions.map((zp) => {
          zp.amount =
            newInventory.inventory_booking_type === 'full' ? 0 : zp.amount;
          return zp;
        })
      );
    }
    await this.stockService.saveLocalForce(this.bufferstock);
    await firstValueFrom(
      this.bufferstockInventoryService.endInventory(
        this.bufferstock.id.toString()
      )
    ).then(() => {
      this.stockService.endInventoryLocal(this.bufferstock.id);
    });
  }

  /**
   * Movement verbuchen
   */
  async book(movement: StockMovementModel, bufferstock?: T) {
    bufferstock = bufferstock ? bufferstock : this.bufferstock;
    await firstValueFrom(this.stockMovementService.save(movement));
    if (!bufferstock || bufferstock.id !== movement.source_id) {
      await this.setBufferStockById(movement.source_id);
    }
    if (bufferstock) {
      if (
        !!movement.target_id &&
        (!this.machineStock || this.machineStock.id !== movement.target_id)
      ) {
        await this.setMachineStockById(movement.target_id);
      }
      await this.updateBufferstock(movement.article_id, movement.amount);
      if (this.machineStock)
        await this.updateMachineStock(
          movement.article_id,
          movement.amount * -1
        );
    } else {
      console.warn('StockInventoryHelperService.book: Bufferstock not set');
    }
  }

  /**
   * Verbuchungen vom normalen Lagerbestand aber nur am Bufferstock
   * @param {number} articleId
   * @param {number} amount
   * @param {number} withBooking soll eine tatsächliche Verbuchung erfolgen.
   */
  async updateInventoryOnlyBufferstock(
    articleId: number,
    amount: number,
    withBooking: boolean = true
  ): Promise<boolean | void> {
    if (amount === 0) {
      return true;
    } else if (this.bufferstock) {
      let result = true;
      result = result && !!(await this.updateBufferstock(articleId, amount));
      if (withBooking) {
        const stockMovement = await this.persistStockMovement(
          articleId,
          amount
        );
        return !!result && !!stockMovement;
      } else {
        return !!result;
      }
    } else {
      console.warn(new StocksNotSetError());
    }
  }

  /**
   * Ziellager (Bufferstock) aktualisieren, d.h. ware geht hier hinzu
   * @param articleId
   * @param amount
   * @returns
   */
  private async updateBufferstock(
    articleId: number,
    amount: number
  ): Promise<boolean | void> {
    if (!this.bufferstock) {
      console.error(
        `[StockInventoryHelperService] Bufferstock not set, tried to book article_id: ${articleId} with amount ${amount}`
      );
      return;
    }
    await this.bookArticle(this.bufferstock, articleId, amount * -1);
    const result = await this.stockService.saveLocalForce(this.bufferstock);
    return !!result;
  }

  /**
   * Quelllager (Maschien) aktualisieren, d.h. Ware geht hiervon ab
   * @param {number} articleId
   * @param {number}  amount
   * @returns
   */
  private async updateMachineStock(
    articleId: number,
    amount: number
  ): Promise<boolean | void> {
    if (!this.machineStock) {
      console.error(
        `[StockInventoryHelperService] Machinestock not set, tried to book article_id: ${articleId} with amount ${amount}`
      );
      return;
    }
    await this.bookArticle(this.machineStock, articleId, amount);
    const result = await this.stockObjService.saveLocalForce(this.machineStock);
    return !!result;
  }

  /**
   * Verbucht einen Artikel auf dem Lager
   * @param {StockObjectModel} stock
   * @param {number} articleId
   * @param {number} amount
   */
  private async bookArticle(
    stock: StockObjectModel,
    articleId: number,
    amount: number
  ) {
    const foundArticle = this.findArticleInStock(articleId, stock);
    if (foundArticle) {
      foundArticle.amount += amount;
    } else {
      const article = await this.loadArticle(articleId);
      stock.positions.push({
        article_id: articleId,
        article_name: article?.name,
        article_number: article?.article_number,
        amount: amount,
      } as any);
    }
  }

  /**
   * Artikel lokal suchen
   * @param articleId
   * @returns
   */
  private async loadArticle(articleId: number): Promise<ArticleModel> {
    const fallbackArticle = { id: articleId } as any;
    try {
      const result = await this.articleService.localFind(articleId);
      return result ? result.content : fallbackArticle;
    } catch (ex) {
      return fallbackArticle;
    }
  }

  /**
   * StockMovement erzeugen
   * @param articleId
   * @param amount
   */
  private async persistStockMovement(
    articleId: number,
    amount: number,
    type: string = 'Warehouse::StockMovements::OrderMovement'
  ): Promise<boolean> {
    if (!this.bufferstock) return false;
    const movement: StockMovementModel = {
      amount: amount,
      type: type,
      article_id: articleId,
      source_id: this.bufferstock.id,
      target_id: this.machineStock?.id,
      created_at: new Date(),
      updated_at: new Date(),
    } as StockMovementModel;
    let result = await firstValueFrom(this.stockMovementService.save(movement));
    return !!result;
  }

  /**
   * Überprüft ob ein Artikel geladen ist
   * @param {ArticleModel} article
   * @return {boolean}
   */
  isLoaded(article: ArticleModel): boolean {
    return this.getCurrentAmount(article?.id) > 0;
  }

  /**
   * Gibt die Menge eines Artikles zurück von dem Bufferstock
   * @param {number} articleId
   * @return {number}
   */
  getCurrentAmount(articleId: number): number {
    let foundArticle = this.findArticleInStock(articleId, this.bufferstock);
    if (foundArticle) {
      return foundArticle.amount;
    }
    return 0;
  }

  /**
   * Gibt die Menge eines entsorgten Artikles zurück von dem Bufferstock
   * @param {number} articleId
   * @return {number}
   */
  getCurrentDisposalAmount(articleId: number): number {
    let foundArticle = this.bufferstock.position_disposals.filter(
      (i) => i.article_id === articleId
    )[0];
    if (foundArticle) {
      return foundArticle.amount;
    }
    return 0;
  }

  /**
   * Gibt die Menge eines Artikles zurück von dem Maschinenstock
   * @param {number} articleId
   * @return {number}
   */
  getMachineStockAmount(articleId: number): number {
    let foundArticle = this.findArticleInStock(articleId, this.machineStock);
    if (foundArticle) {
      return foundArticle.amount;
    }
    return 0;
  }

  /**
   * Suche den Artikel im Stock
   * @param {number} articleId
   * @returns
   */
  private findArticleInStock(
    articleId: number,
    stock: StockObjectModel
  ): StockPositionModel {
    if (stock) {
      if (!stock.positions) return null;
      return stock.positions.filter((i) => i.article_id === articleId)[0];
    }
    return null;
  }

  /**
   * Verbuchen von mehreren Entsorgungen durchführen.
   * @param positions
   */
  public async bookDisposalsOnBufferstock(positions: PositionModel[]) {
    for (const position of positions) {
      await this.bookDisposalOnBufferstock(position.article, position.amount);
    }
  }

  /**
   * Verbuchung einer Entsorgung durchführen
   * 1. Maschinenstock menge abziehen (local)
   * 2. Entsorgung auf Bufferstock verbuchen (local)
   * 3. Trash stockmovment erzeugen
   * @param article
   * @param amount
   */
  public async bookDisposalOnBufferstock(
    article: ArticleModel,
    amount: number
  ) {
    // Menge auf dem Maschinestock abziehen
    if (this.machineStock) {
      await this.updateMachineStock(article.id, amount * -1);
    } else {
      console.warn('Maschinenstock nicht gesetzt');
    }

    // If bedingung ist falls eine Maschine oder ein Auftrag ohne Bufferstock bearbeitet wird
    if (this.bufferstock) {
      // 2. Menge auf dem Bufferstock bei Entsorgungen erhöhen
      // 2.1. Menge lokal verbuchen
      await this.saveDisposalOnBufferstock(article, amount);
      // 2.2. Trash stockmovment erzeugen
      await this.persistStockMovement(
        article.id,
        amount,
        'Warehouse::StockMovements::MachineTrashMovement'
      );
    }
  }

  /**
   * Verbuchung der Entsorgung auf dem Bufferstock durchführen
   * @param article or articleId
   * @param amount
   */
  public async saveDisposalOnBufferstock(
    article: ArticleModel | number,
    amount: number
  ) {
    if (!this.bufferstock) return;

    if (typeof article === 'number') {
      article = await this.loadArticle(article);
    }

    const disposalPosition = this.getStockPositionDisposal(article);

    if (disposalPosition) {
      if (GlobalHelper.isZero(disposalPosition.amount))
        disposalPosition.amount = 0;
      disposalPosition.amount += amount;
    } else {
      this.bufferstock.position_disposals.push(
        this.buildNewDisposalPosition(article, amount)
      );
    }
    await this.stockService.saveLocalForce(this.bufferstock);
  }

  /**
   * Stock Position aus Buffertock holen
   * @param article
   * @returns
   */
  private getStockPositionDisposal(
    article: ArticleModel
  ): StockPositionDisposalModel {
    return this.bufferstock.position_disposals?.find(
      (tp) => tp.article_id === article.id
    );
  }

  /**
   * Neue Entsorgungsposition bauen
   * @param article
   * @param amount
   * @returns
   */
  private buildNewDisposalPosition(
    article: ArticleModel,
    amount: number
  ): StockPositionDisposalModel {
    return {
      amount: amount,
      article_id: article.id,
      article: article,
      bufferstock_id: this.bufferstock.id,
      bufferstock: this.bufferstock,
    } as StockPositionDisposalModel;
  }
}
