import { Injectable } from '@angular/core';
import { StockObjectModel } from 'src/packages/warehouse';
import { StockObjectService } from 'src/packages/warehouse/providers/stockObject.service';

export interface InventoryCounter {
  activeCount: string;
}

@Injectable({
  providedIn: 'root',
})
export class InventoriesHelper {
  // All stockObjects that currently have an required inventory
  requiredInventoryStocks: StockObjectModel[] = [];
  requiredInventoryStocksCount = 0;

  constructor(private readonly stockObjectService: StockObjectService) {}

  /**
   * Loads all stockObjects that have an required inventory
   * @returns loaded stockObjects
   */
  public async loadRequiredInventories(): Promise<StockObjectModel[]> {
    this.requiredInventoryStocks =
      await this.stockObjectService.localWhereWithoutPaging({
        inventory_required: true,
      });
    this.requiredInventoryStocksCount = this.requiredInventoryStocks.length;
    return this.requiredInventoryStocks;
  }

  // the counters for the inventories
  getCounters(): InventoryCounter {
    return {
      activeCount: this.requiredInventoryStocksCount.toString(),
    };
  }
}
