import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { AppComponent } from 'src/app/app.component';
import { SystemHelper } from 'src/app/providers/helpers/system-helper';
import { UserHelper } from 'src/app/providers/helpers/user-helper.service';
import { DevelopmentHelper } from '../../helpers/development.helper';

@Component({
  selector: 'dev-options',
  templateUrl: './dev-options.html',
})
export class DevOptions {
  constructor(
    public readonly appComp: AppComponent,
    public readonly devHelper: DevelopmentHelper,
    public readonly systemHelper: SystemHelper,
    public readonly userHelper: UserHelper,
    private readonly popoverCtrl: PopoverController,
    private readonly router: Router
  ) {
    if (this.systemHelper.isDeveloper)
      this.devHelper.activateDeveloperModeCounter = 0;
  }

  open(url: string) {
    this.popoverCtrl.dismiss();
    this.router.navigateByUrl(url);
  }

  reloadApp() {
    this.popoverCtrl.dismiss();
    this.router.navigateByUrl('/').then(() => {
      window.location.reload();
    });
  }

  exitDevMode() {
    this.popoverCtrl.dismiss();
    this.devHelper.clickAppVersion();
  }
}
