import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MitsCheckButtonModule } from 'src/app/components/mits-check-button/mits-check-button.module';
import { MitsHeaderModule } from 'src/app/components/mits-header/mits-header.module';
import { BufferstockLoadByBookingPageRoutingModule } from './bufferstock-load-by-booking-routing.module';
import { BufferstockLoadByBookingPage } from './bufferstock-load-by-booking.page';
import { MitsNumberBoxModule } from 'src/app/components/mits-number-box/mits-number-box.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BufferstockLoadByBookingPageRoutingModule,
    MitsHeaderModule,
    MitsCheckButtonModule,
    MitsNumberBoxModule,
  ],
  declarations: [BufferstockLoadByBookingPage],
})
export class BufferstockLoadByBookingPageModule {}
