import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mits-check-button',
  templateUrl: './mits-check-button.component.html',
  styleUrls: ['./mits-check-button.component.scss'],
})
export class MitsCheckButtonComponent {
  /** State of the button */
  @Input() state = false;
  /** Disables the button */
  @Input() disable = false;
  /** Disables the change making and lets you take the control */
  @Input() skipChange = false;
  /** Output Changed-Event of state */
  @Output() stateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Changes/Adds value by step
   */
  clickButton(ev?: Event): void {
    if (ev) ev.stopPropagation();
    if (!this.skipChange) this.state = !this.state;
    this.stateChange.emit(this.state);
  }
}
