import { Injectable } from '@angular/core';
import { UserHelper } from 'src/app/providers/helpers/user-helper.service';
import { ArticleService } from 'src/app/providers/model-services/article.service';
import { IInventoryHelper } from 'src/packages/mitsBasics/interfaces';
import { BufferstockModel } from '../models';
import { BufferstockService } from '../providers/bufferstock.service';
import { BufferstockInventoryService } from '../providers/buffertock-inventory.service';
import { StockMovementService } from '../providers/stockMovement.service';
import { StockObjectService } from '../providers/stockObject.service';
import { BasicStockInventoryHelperService } from './basic-stock-inventory-helper';

/**
 * Verbuchung auf den Lägern
 */
@Injectable({
  providedIn: 'root',
})
export class StockInventoryHelperService
  extends BasicStockInventoryHelperService<BufferstockModel>
  implements IInventoryHelper
{
  constructor(
    protected stockService: BufferstockService,
    protected stockInventoryService: BufferstockInventoryService,
    protected stockMovementService: StockMovementService,
    protected articleService: ArticleService,
    protected readonly stockObjService: StockObjectService,
    protected userHelper: UserHelper
  ) {
    super(
      articleService,
      stockInventoryService,
      stockService,
      stockMovementService,
      stockObjService,
      userHelper
    );
  }
}
