import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BoilerplateModel } from 'src/app/models/settings/boilerplate';
import { ErrorService } from '../error.service';
import { EventsService } from '../events.service';
import { OfflineDataService } from '../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class BoilerplateService extends OfflineDataService<BoilerplateModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'System::Boilerplate',
      http,
      errorService,
      events,
      'system/boilerplates/',
      'system_boilerplate',
      ['created_at', 'updated_at', 'created_by_id'],
      []
    );
  }

  load_categories(): Observable<any> {
    return this.http
      .get<any>(this.endpointWithUrl + 'categories')
      .pipe(retry(1), catchError(this.errorService.convert));
  }
}
