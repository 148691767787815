import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from "@angular/router";

export interface CanComponentDeactivate {
  canDeactivate: () => Promise<boolean>;
  isPopupShown: boolean;
}

@Injectable()
export class CanDeactivateGuard
  implements CanDeactivate<CanComponentDeactivate>
{
  private calledPromise: Promise<boolean>;

  canDeactivate(
    component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!this.calledPromise) {
      this.calledPromise = component.canDeactivate();
    }

    return this.calledPromise.then((result) => {
      this.calledPromise = null;
      return result;
    });
  }
}
