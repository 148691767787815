<mits-header [modal]="true" [title]="'Einlagern eingehender Buchungen'"></mits-header>

<ion-content>
  <ion-list [inset]="true">
    <ion-item *ngIf="!bookings || bookings.length === 0">
      <ion-label>Keine eingehenden Buchungen gefunden</ion-label>
    </ion-item>
    <ng-container *ngFor="let booking of bookingsWithMaxValue">
      <ion-item>
        <ion-label class="ion-text-wrap">
          <strong>{{booking.article.name}} {{booking.article.article_number}}</strong>
          <div>{{booking.source.name | slice: 0:50}}</div>
          <div>
            {{booking.created_by.lastname}}, {{booking.created_by.firstname}} am
            {{booking.updated_at | date:"dd.MM.yyyy hh:mm"}}
          </div>
        </ion-label>
        <mits-number-box [maxValue]="booking.maxvalue" [(value)]="booking.amount"></mits-number-box>
        <mits-check-button (stateChange)="addVerification($event, booking)"></mits-check-button>
        <ion-icon slot="end" name="globe-outline" color="secondary" size="small"
          *ngIf="booking.target_id !== bufferstock.id"></ion-icon>
      </ion-item>
    </ng-container>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-row>
    <ion-col>
      <ion-button color="danger" expand="block" (click)="closeModal()">
        Abbrechen
      </ion-button>
    </ion-col>
    <ion-col>
      <ion-button color="primary" expand="block" (click)="save()">Speichern</ion-button>
    </ion-col>
  </ion-row>
</ion-footer>