import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ResponseModel } from 'src/app/models/response';
import { FunctionSwitchModel } from 'src/app/models/settings/function-switch';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root',
})
export class FunctionSwitchService extends DataService<FunctionSwitchModel> {
  endpoint = 'system/function_switches/';
  objectName = 'system_function_switch';

  endpointWithoutPaginate = this.endpointWithUrl + 'all/';

  allWithoutPaginate(): Observable<ResponseModel<FunctionSwitchModel>> {
    return this.http
      .get<ResponseModel<FunctionSwitchModel>>(this.endpointWithoutPaginate)
      .pipe(retry(1), catchError(this.errorService.convert));
  }
}
