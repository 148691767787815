import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { catchError, Observable, retry } from 'rxjs';
import { CustomerModel } from 'src/app/models/customers/customer';
import { ErrorService } from '../error.service';
import { EventsService } from '../events.service';
import { OfflineDataService } from '../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends OfflineDataService<CustomerModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Customer',
      http,
      errorService,
      events,
      'customers/',
      'customer',
      [
        'created_at',
        'updated_at',
        'created_by_id',
        'open_orders',
        'open_damages',
      ],
      ['locations', 'contacts']
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'footsteps';
  }

  allWithoutPagy(): Observable<CustomerModel[]> {
    return this.http
      .get<any>(this.endpointWithUrl + 'all')
      .pipe(retry(1), catchError(this.errorService.convert));
  }
}
