import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { BufferstockModel } from '../models';
import { StockWithInventoryService } from './StockWithInventory.service';

@Injectable({
  providedIn: 'root',
})
export class BufferstockService extends StockWithInventoryService<BufferstockModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Warehouse::Bufferstock',
      http,
      errorService,
      events,
      'warehouse/bufferstocks/',
      'warehouse_bufferstock',
      ['created_at', 'updated_at', 'type', 'positions'],
      ['baskets']
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'albums-outline';
  }
}
