import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { SystemHelper } from 'src/app/providers/helpers/system-helper';
import { SyncWrapperService } from 'src/app/providers/syncWrapper.service';

@Injectable()
export class DevelopmentHelper {
  public totalOfflineDataAmount: number;
  // Aktiviert den Entwicklermodus nach 5x mal klicken auf die Versionsnummer
  public activateDeveloperModeCounter = 5;

  constructor(
    private readonly alertCtrl: AlertController,
    private readonly syncWrapperService: SyncWrapperService,
    private readonly systemHelper: SystemHelper
  ) {
    this.loadOfflineData();
  }

  async loadOfflineData() {
    if (this.systemHelper.isDeveloper)
      this.totalOfflineDataAmount =
        await this.syncWrapperService.getAmountOfData();
  }

  async clickAppVersion() {
    this.activateDeveloperModeCounter--;
    if (this.activateDeveloperModeCounter === 0) {
      const alert = await this.alertCtrl.create({
        header: 'Entwicklermodus aktivieren?',
        message:
          'Der Entwicklermodus is nur für Entwickler gedacht! Dadurch können Daten verloren gehen!',
        buttons: [
          {
            text: 'Zurück',
            role: 'cancel',
            handler: () => {
              this.activateDeveloperModeCounter = 5;
            },
          },
          {
            text: 'Fortfahren',
            role: 'confirm',
            cssClass: 'colorDebug',
            handler: async () => {
              this.totalOfflineDataAmount =
                await this.syncWrapperService.getAmountOfData();
              this.systemHelper.isDeveloper = true;
              sessionStorage.setItem('developer', 'true');
            },
          },
        ],
      });
      alert.present();
    } else if (this.activateDeveloperModeCounter < 0) {
      this.systemHelper.isDeveloper = false;
      sessionStorage.removeItem('developer');
      this.activateDeveloperModeCounter = 5;
    }
  }
}
