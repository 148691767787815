import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DAMAGE_MANAGEMENT_BASE_ROUTE } from '../packages/damageManagement/damageManagement-routing.module';
import { TOUR_MANAGEMENT_BASE_ROUTE } from '../packages/tourManagement/tourManagement-routing.module';
import { WAREHOUSE_BASE_ROUTE } from '../packages/warehouse/warehouse-routing.module';
import { CanDeactivateGuard } from './guards/canDeactivate.guard';
import { AccessGuard } from './providers/access.guard';

import {
  TOUR_CHECKLIST_SERVICE_TOKEN,
  TOUR_LOCATION_SERVICE_TOKEN,
  TOUR_MACHINE_SERVICE_TOKEN,
  TOUR_USER_SERVICE_TOKEN,
} from 'src/packages/tourManagement/tourManagement.tokens';
import { ChecklistServiceTourAdapter } from './providers/model-services/adapters/checklist.service.tour.adapter';
import { LocationServiceTourAdapter } from './providers/model-services/adapters/location.service.tour.adapter';
import { MachineServiceTourAdapter } from './providers/model-services/adapters/machine.service.tour.adapter';
import { UserServiceTourAdapter } from './providers/model-services/adapters/user.service.tour.adapter';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard-worker',
    pathMatch: 'full',
  },
  {
    path: 'statusboard',
    loadChildren: () =>
      import('./pages/backend/statusboard/statusboard.module').then(
        (m) => m.StatusboardPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'dev/offline-data',
    loadChildren: () =>
      import('./developers/pages/offline-data/offline-data.module').then(
        (m) => m.OfflineDataPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'dev/performance',
    loadChildren: () =>
      import(
        './developers/pages/performance-tester/performance-tester.module'
      ).then((m) => m.PerformanceTesterPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'dashboard-inside',
    loadChildren: () =>
      import('./pages/backend/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'machines',
    loadChildren: () =>
      import('./pages/masterdata/machines/machines.module').then(
        (m) => m.MachinesPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'timelogs',
    loadChildren: () =>
      import('./pages/masterdata/timelogs/timelogs.module').then(
        (m) => m.TimelogsPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'help',
    loadChildren: () =>
      import('./pages/options/help/help.module').then((m) => m.HelpPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/backend/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./pages/processing/orders/orders.module').then(
        (m) => m.OrdersPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'inventories/active',
    loadChildren: () =>
      import(
        './pages/processing/inventories/inventories-active/inventories-active.module'
      ).then((m) => m.InventoriesActivePageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'emergency-order/:id',
    loadChildren: () =>
      import('./pages/processing/emergency-order/emergency-order.module').then(
        (m) => m.EmergencyOrderPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'service_contracts',
    loadChildren: () =>
      import('./pages/backend/service-contracts/service-contracts.module').then(
        (m) => m.ServiceContractsPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'timelog-corrections',
    loadChildren: () =>
      import(
        './pages/backend/timelog-corrections/timelog-corrections.module'
      ).then((m) => m.TimelogCorrectionsPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'cars',
    loadChildren: () =>
      import('./pages/masterdata/cars/cars.module').then(
        (m) => m.CarsPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'checklists',
    loadChildren: () =>
      import(
        '../packages/checklistManagement/pages/checklists/checklists.module'
      ).then((m) => m.ChecklistsPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'templates',
    loadChildren: () =>
      import(
        '../packages/templateManagement/pages/templates/templates.module'
      ).then((m) => m.TemplatesPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: TOUR_MANAGEMENT_BASE_ROUTE,
    loadChildren: () =>
      import('../packages/tourManagement/tourManagement-routing.module').then(
        (m) => m.TourManagementRoutingModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('./pages/masterdata/customers/customers.module').then(
        (m) => m.CustomersPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'mits-scanner-modal',
    loadChildren: () =>
      import(
        './components/mits-scanner-button/mits-scanner-modal/mits-scanner-modal.module'
      ).then((m) => m.MitsScannerModalPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'dashboard-worker',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: DAMAGE_MANAGEMENT_BASE_ROUTE,
    loadChildren: () =>
      import(
        '../packages/damageManagement/damageManagement-routing.module'
      ).then((m) => m.DamageManagementRoutingModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'quick-start',
    loadChildren: () =>
      import('./pages/processing/quick-start/quick-start.module').then(
        (m) => m.QuickStartPageModule
      ),
  },
  {
    path: WAREHOUSE_BASE_ROUTE,
    loadChildren: () =>
      import('../packages/warehouse/warehouse-routing.module').then(
        (m) => m.WarehouseRoutingModule
      ),
    canActivate: [AccessGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  providers: [
    CanDeactivateGuard,
    { provide: TOUR_USER_SERVICE_TOKEN, useClass: UserServiceTourAdapter },
    {
      provide: TOUR_MACHINE_SERVICE_TOKEN,
      useClass: MachineServiceTourAdapter,
    },
    {
      provide: TOUR_LOCATION_SERVICE_TOKEN,
      useClass: LocationServiceTourAdapter,
    },
    {
      provide: TOUR_CHECKLIST_SERVICE_TOKEN,
      useClass: ChecklistServiceTourAdapter,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
