import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { UserModel } from 'src/app/models/users/user';
import { AccessGuard } from 'src/app/providers/access.guard';
import { FunctionSwitchHelperService } from 'src/app/providers/component-helpers/function-switch.service';
import { ToastService } from 'src/app/providers/component-helpers/toast.service';
import { ToggleMenuHelper } from 'src/app/providers/component-helpers/toggle-menu-helper.service';
import { ErrorService } from 'src/app/providers/error.service';
import { UserHelper } from 'src/app/providers/helpers/user-helper.service';
import { PageService } from 'src/app/providers/page-services/page.service';
import { StockInventoryHelperService } from 'src/packages/warehouse/helpers/stock-inventory-helper';
import { BufferstockModel } from 'src/packages/warehouse/models';
import { StockMovementModel } from 'src/packages/warehouse/models/stockMovement';
import { BufferstockService } from 'src/packages/warehouse/providers';
import { BufferstockInventoryService } from '../../../providers/buffertock-inventory.service';
import { StockMovementService } from './../../../providers/stockMovement.service';

//** interface for Stockmovements to create a max value  */
interface StockMovementWithMaxValue extends StockMovementModel {
  maxvalue: number;
}
@Component({
  selector: 'app-bufferstock-load-by-booking',
  styleUrls: ['./bufferstock-load-by-booking.page.scss'],
  templateUrl: './bufferstock-load-by-booking.page.html',
})
export class BufferstockLoadByBookingPage
  extends PageService
  implements OnInit
{
  bufferstock?: BufferstockModel;
  bookings: StockMovementModel[];
  bookingsWithMaxValue: StockMovementWithMaxValue[] = [];

  // Current User
  user: UserModel;

  constructor(
    public readonly accessGuard: AccessGuard,
    public readonly device: Platform,
    public readonly errorService: ErrorService,
    public readonly functionSwitchHelper: FunctionSwitchHelperService,
    public readonly location: Location,
    public readonly route: ActivatedRoute,
    public readonly router: Router,
    public readonly toastService: ToastService,
    public readonly toggleMenuHelper: ToggleMenuHelper,
    public readonly bufferstockInventoryService: BufferstockInventoryService,
    public readonly fb: FormBuilder,
    public readonly alertCtrl: AlertController,
    private readonly bufferstockService: BufferstockService,
    private readonly modalCtrl: ModalController,
    private readonly stockInventoryHelper: StockInventoryHelperService,
    private readonly stockMovementService: StockMovementService,
    private readonly userHelper: UserHelper
  ) {
    super(
      accessGuard,
      device,
      errorService,
      functionSwitchHelper,
      location,
      route,
      router,
      toastService,
      toggleMenuHelper
    );
  }

  async ngOnInit() {
    if (!this.bookings && this.bufferstock) await this.loadBookings();
    this.addMaxValueToStockMovements();
    this.user = this.userHelper.getUser();
  }

  /**
   * Adds maxvalue to the loaded stockMovements
   */

  addMaxValueToStockMovements() {
    for (const move of this.bookings) {
      const moveMax: StockMovementWithMaxValue = {
        ...move,
        maxvalue: move.amount,
      };
      this.bookingsWithMaxValue.push(moveMax);
    }
  }

  /**
   * Closes the Modal without selection or changes
   */
  closeModal() {
    this.modalCtrl.dismiss();
  }

  /**
   * Wenn eine Buchung selektiert oder deselektiert wird, wird diese
   * Funktion aufgerufen. An die Funktion wird eine einzelne Buchung übergeben.
   * Dem Attribut verified_by_id wird bei Selektion die ID des eingeloggten Users
   * angefügt. Im anderen Fall wird das Attribut auf null gesetzt.
   * @param event boolean ob entsprechende Buchung selektiert ist, oder nicht.
   * @param booking ist die entsprechende Buchung.
   */
  addVerification(event, booking) {
    if (event) booking.verified_by_id = this.user.id;
    else booking.verified_by_id = null;
  }

  /**
   * Fehlermeldung liefern, dass die Mengen nicht stimmen.
   * Gibt die Button-Optionen für die Alertbox zurück.
   * @returns
   */
  private async showAlertAmountAndGetReturnValue(): Promise<string> {
    const alert = await this.alertCtrl.create({
      header: 'Artikel verbuchen',
      message:
        'Einige Mengen stimmen mit den gelieferten nicht überein, sollen diese zurückgebucht werden ?',
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        },
        {
          text: 'Bestätigen',
          role: 'confirm',
        },
      ],
    });

    await alert.present();

    return (await alert.onDidDismiss()).role;
  }

  /**
   * Prüft, ob die Mengen in den Buchungen mit den gelieferten Mengen übereinstimmen.
   * @returns
   */
  private amountChangedInOnePosition(): boolean {
    return this.bookingsWithMaxValue.some(
      (booking) => booking.maxvalue != booking.amount
    );
  }

  /**
   * Vollendet die bestätigten Buchungen. Für jede Buchung, die eine verified_by_id
   * gesetzt hat, wird ein request mit den Parametern booking_id und user_id geschickt.
   */
  async save() {
    // Wenn die Mengen nicht stimmen, wird eine Alertbox angezeigt und falls notwendig aus dem Prozess rausgesprungen.
    if (this.amountChangedInOnePosition()) {
      const role = await this.showAlertAmountAndGetReturnValue();
      if (role === 'cancel') return;
    }

    for (const move of this.verifiedBookings) {
      this.stockInventoryHelper.bufferstock = await firstValueFrom(
        this.bufferstockService.find(move.target_id)
      );
      await this.processBooking(move);
    }

    this.closeModal();
  }

  /**
   * Verifizierte Buchungen zurückgeben
   * @returns {StockMovementWithMaxValue[]}
   */
  private get verifiedBookings(): StockMovementWithMaxValue[] {
    return this.bookingsWithMaxValue.filter(
      (booking) => booking.verified_by_id > 0
    );
  }

  /**
   * Eine Buchung verarbeiten
   * @param {StockMovementModel} move
   */
  private async processBooking(move: StockMovementWithMaxValue) {
    // Mengen stimmen überein, nur auf dem Zielbufferstock einbuchen
    if (move.maxvalue === move.amount) {
      await this.moveBookingToTarget(move);
    } else {
      // Mengen stimmen nicht überein: Zielbufferstock einbuchen und Teil auf den Quellbufferstock zurückbuchen.
      await this.bookDifferenceBack(move);
      await this.moveBookingToTarget(move);
    }
  }

  /**
   * Abweichung zurück auf das Quelllager buchen
   * @param move
   */
  private async bookDifferenceBack(move: StockMovementWithMaxValue) {
    const difference = move.maxvalue - move.amount;
    const newMove = this.newMovement(move, difference);
    await this.stockInventoryHelper.book(newMove);
  }

  /**
   * Menge auf dem Ziel verbuchen und die Buchung lokal löschen
   * @param move
   */
  private async moveBookingToTarget(move: StockMovementWithMaxValue) {
    await firstValueFrom(
      this.stockMovementService.letsMoveTheBooking(move.id, this.user.id)
    );
    // Hier wird die MaxValue verbucht, dies wird zunächst mehr auf den Stock buchen.
    // Gegenbuchung mit Differenz erfolgt in der Methode bookDifferenceBack.
    await this.stockInventoryHelper.updateInventoryOnlyBufferstock(
      move.article_id,
      move.maxvalue * -1,
      false
    );
    await this.stockMovementService.localDelete(move.id);
  }

  /**
   * Neues Movement für die Rückuchung erstellen.
   * Source und Target tauschen
   * @param move
   * @param difference
   * @returns
   */
  newMovement(
    move: StockMovementModel,
    difference: number
  ): StockMovementModel {
    const user = this.userHelper.getUser();
    return {
      article_id: move.article_id,
      amount: difference,
      source_id: move.target_id,
      target_id: move.source_id,
      created_by_id: user.id,
      type: 'Warehouse::StockMovements::NormalMovement',
      verified_by_id: user.id,
      book_on_save: true,
    };
  }

  /**
   * Lädt alle bookings, deren target_id der des ausgewählten Bufferstocks entspricht.
   * Anschließend wird das Array dem Erstelldatum nach absteigend sortiert.
   * Außerdem wird zur Darstellung das updated_at-Datum in ein leserliches Format konvertiert.
   */
  private async loadBookings() {
    if (!this.bufferstock?.id) return;
    const results = await firstValueFrom(
      this.stockMovementService.whereRemote({
        target_id: this.bufferstock?.id,
      })
    );
    // TODO: artikel aus artikeldatenbank laden und an "source" anhängen
    this.bookings = results.data;
  }
}
