import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { toFormData } from 'src/app/components/mits-files-upload/mits-files-upload-select/helpers/formDataParser';
import { BusinessModel } from 'src/app/models/settings/business';
import { ErrorService } from '../error.service';
import { EventsService } from '../events.service';
import { OfflineDataService } from '../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class BusinessService extends OfflineDataService<BusinessModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Business',
      http,
      errorService,
      events,
      'businesses/',
      'business',
      ['created_at', 'updated_at', 'created_by_id'],
      ['attachments']
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'business';
  }

  saveWithImage(business: BusinessModel): Observable<BusinessModel> {
    let keys = ['logo', 'report_header', 'report_footer'];

    keys.forEach((key) => {
      let element = business[key];

      if (element && element.length > 0) business[key] = element[0];
    });

    if (business.id >= 0) {
      return this.http
        .put<BusinessModel>(
          this.endpointWithUrl + business.id,
          toFormData(business, this, ['logo', 'report_header', 'report_footer'])
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    } else {
      return this.http
        .post<BusinessModel>(
          this.endpointWithUrl,
          toFormData(business, this, ['logo', 'report_header', 'report_footer'])
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    }
  }
}
