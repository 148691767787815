import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventsService } from 'src/app/providers/events.service';
import { Progress } from 'src/app/providers/helpers/initialization-helper';

@Component({
  selector: 'initialization',
  templateUrl: './initialization.component.html',
  styleUrls: ['./initialization.component.scss'],
})
export class InitializationComponent {
  tasks: Progress[] = [];
  currentTaskIndex: number = 0;

  readonly stateColor = {
    pending: 'tertiary',
    inProgress: 'primary',
    completed: 'success',
  };

  constructor(
    public readonly eventsService: EventsService,
    private readonly modalCtrl: ModalController
  ) {}

  ionViewDidEnter(): void {
    if (this.tasks.length > 0) {
      this.tasks.forEach((task) => {
        if (task.proggressEvent) this.subscribeToProgress(task);
        else {
          task.percentage = 0;
          task.status = 'pending';
        }
        this.subscribeToCompleted(task);
      });
      this.startTask(this.tasks[this.currentTaskIndex]);
    } else {
      this.currentTaskIndex = null;
      this.dismissInitialization();
    }
  }

  showRemaining(task: Progress) {
    const startedDate = new Date(task.startedAt);
    const currentDate = new Date();
    return currentDate.getTime() - startedDate.getTime() > 10000;
  }

  private dismissInitialization() {
    this.modalCtrl.dismiss();
  }

  private startTask(task: Progress) {
    this.eventsService.publish(task.startEvent, {});
    task.status = 'inProgress';
    task.startedAt = new Date();
  }

  private subscribeToProgress(task: Progress) {
    task.status = 'pending';
    this.eventsService
      .subscribe(task.proggressEvent)
      .subscribe((percentage) => {
        task.percentage = percentage;
        const current = new Date();
        const elapsed = current.getTime() - task.startedAt.getTime();
        const remaining = (100 - percentage) * (elapsed / percentage);
        task.timeRemaining = remaining;
      });
  }

  private subscribeToCompleted(task: Progress) {
    this.eventsService.subscribe(task.completedEvent).subscribe(() => {
      task.status = 'completed';
      task.percentage = 100;
      this.nextTask();
    });
  }

  private nextTask() {
    this.currentTaskIndex++;
    if (this.currentTaskIndex >= this.tasks.length) {
      this.currentTaskIndex = null;
      setTimeout(() => {
        this.dismissInitialization();
      }, 250);
      return;
    }
    this.startTask(this.tasks[this.currentTaskIndex]);
  }
}
