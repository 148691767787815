import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { MachineModel } from 'src/app/models/machine';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';
import { OfflineDataService } from '../../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class MachineService extends OfflineDataService<MachineModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Machine',
      http,
      errorService,
      events,
      'machines/',
      'machine',
      [
        'created_at',
        'updated_at',
        'created_by_id',
        'customer',
        'open_damages',
        'category',
      ],
      [
        'customer_location',
        'planogram_articles',
        'planogram',
        'layout',
        'select_buttons',
      ]
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'cafe-outline';
  }
}
