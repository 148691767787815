import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import * as Sentry from '@sentry/angular-ivy';
import {
  SyncEngineClientModule,
  dbConfig,
} from '@vending/sync-engine-client/dist/sync-engine-client';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { BufferstockLoadByBookingPageModule } from 'src/packages/warehouse/pages/bufferstock-details/bufferstock-load-by-booking/bufferstock-load-by-booking.module';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLogoModule } from './components/app-logo/app-logo.module';
import { InitializationModule } from './components/initialization/initialization.module';
import { MitsBadgeModule } from './components/mits-badge/mits-badge.module';
import { MitsHeaderModule } from './components/mits-header/mits-header.module';
import { MitsIconModule } from './components/mits-icon/mits-icon.module';
import { DevOptionsModule } from './developers/pages/dev-options/dev-options.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { BusinessInterceptor } from './interceptors/business.interceptor';
import {
  DEFAULT_TIMEOUT,
  TimeoutInterceptor,
} from './interceptors/timeout.interceptor';
import { TimelogHelper } from './pages/processing/orders/order-details/helper/timelog-helper';
import { ToggleMenuHelper } from './providers/component-helpers/toggle-menu-helper.service';
import { InitializationHelper } from './providers/helpers/initialization-helper';

registerLocaleData(localeDe, 'de-DE');

const REALM_FILE = 'assets/environments/realm.json';
const KEYCLOAK_URL = 'https://vending-neu-auth.muench-its.de/auth';
const KEYCLOAK_CLIENT_ID = 'app';

interface KeycloakEnvironmentParams {
  name: string;
  clientId: string;
  keycloak_url: string;
}

/**
 * Lädt das Realm von den Assets (realm.json)
 * parsedBody = {
 *   "name": "Realm-Name",
 *   "clientId": "app", (optional)
 *   "keycloak_url": "https://meine-keycloak-instanz.de/auth" (optional)
 * }
 */
function loadRealmFromAssets(): Promise<KeycloakEnvironmentParams> {
  return new Promise(async (resolve, reject) => {
    try {
      const cache: Cache = await caches.open('realmFile');

      const response = await cache.match(REALM_FILE);

      if (response) {
        resolve(JSON.parse(await response.text()));
      } else {
        const result = await fetch(REALM_FILE);

        cache.put(REALM_FILE, result.clone());

        const body = await result.text();
        const parsedBody = JSON.parse(body);
        resolve(parsedBody);
      }
    } catch (error) {
      console.error(error);
      resolve({} as KeycloakEnvironmentParams);
    }
  });
}

export function initializeKeycloak(keycloak: KeycloakService) {
  return async () => {
    let realm;
    let keycloakUrl = KEYCLOAK_URL;
    let clientId = KEYCLOAK_CLIENT_ID;

    try {
      const params = await loadRealmFromAssets();
      if (params.name) realm = params.name;
      if (params.clientId) clientId = params.clientId;
      if (params.keycloak_url) keycloakUrl = params.keycloak_url;
    } catch (ex) {
      console.error(ex);
    }

    if (!realm) {
      realm = 'Vending-neu';
      console.error('ACHTUNG: REALM NOT SET. TO DEFAULT VENDING-TEST');
    }

    // Tokens werden benötigt, so dass kein infinite Loop beim Refresh passiert.
    // siehe Ticket https://gitlab.muench-its.de/vending/management/-/issues/792
    const token = localStorage.getItem('kc_token');
    const refreshToken = localStorage.getItem('kc_refreshToken');

    const initOptions = {
      checkLoginIframe: false,
    };

    if (token && token !== 'undefined') {
      initOptions['token'] = token;
    }
    if (refreshToken && refreshToken !== 'undefined') {
      initOptions['refreshToken'] = refreshToken;
    }

    return keycloak
      .init({
        config: {
          url: keycloakUrl,
          realm,
          clientId,
        },
        initOptions: initOptions,
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/assets'],
      })
      .then((auth) => {
        localStorage.setItem('kc_token', keycloak.getKeycloakInstance().token);
        localStorage.setItem(
          'kc_refreshToken',
          keycloak.getKeycloakInstance().refreshToken
        );

        if (!auth) {
          keycloak.login({
            scope: 'offline_access',
          });
        }
      })
      .catch((error) => {
        console.error('Keycloak init failed', error);
        if (navigator.onLine) {
          localStorage.removeItem('kc_token');
          localStorage.removeItem('kc_refreshToken');
        }
      });
  };
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    MitsHeaderModule,
    BrowserModule,
    IonicModule.forRoot({ animated: false, mode: 'md' }),
    AppRoutingModule,
    HttpClientModule,
    KeycloakAngularModule,
    AppLogoModule,
    FormsModule,
    MitsIconModule,
    DevOptionsModule,
    SyncEngineClientModule,
    MitsBadgeModule,
    BufferstockLoadByBookingPageModule,
    InitializationModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    ToggleMenuHelper,
    TimelogHelper,
    DecimalPipe,
    DatePipe,
    InitializationHelper,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BusinessInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 30 * 1000 }],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
