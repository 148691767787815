import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CarService } from 'src/packages/carManagement';
import { BusinessModel } from '../../models/settings/business';
import { MandatorModel } from '../../models/settings/mandator';
import { SettingsModel } from '../../models/settings/settings';
import { UserModel } from '../../models/users/user';
import { EventsService } from '../events.service';
import { BusinessService } from '../model-services/business.service';
import { MandatorService } from '../model-services/mandator.service';
import { UserService } from '../model-services/user.service';
import * as Sentry from '@sentry/browser';

export interface IMandatorBusinessResponse {
  mandator: MandatorModel;
  business: BusinessModel;
}

@Injectable({
  providedIn: 'root',
})
export class UserHelper {
  /** Currently logged in user */
  private currentUser: UserModel;
  /** Currently selected Mandator */
  private currentMandator: MandatorModel;
  /** Currently selected business */
  private currentBusiness: BusinessModel;
  /** Current settings wich are filtered */
  private settings: SettingsModel;

  _userLoaded: boolean = false;

  constructor(
    private readonly businessService: BusinessService,
    private readonly carService: CarService,
    private readonly events: EventsService,
    private readonly mandatorService: MandatorService,
    private readonly userService: UserService
  ) {
    this.loadUser();
  }

  /**
   * sets the currently logged in user
   * @param user to set
   */
  public setUser(user: UserModel): void {
    this.currentUser = user;
    Sentry.setUser(
      user
        ? {
            username: user.username,
            email: user.email,
            id: user.id.toString(),
            ip_address: '{{auto}}',
          }
        : null
    );
  }

  /**
   * Get the currently logged in user
   * @returns logged in user
   */
  public getUser(): UserModel {
    return this.currentUser;
  }

  /**
   * Generates a readable identity of the provided user
   * @param user User to identify
   * @returns indentity as a string
   */
  public getUserString(user: UserModel): string {
    if (!user) return 'No User';
    if (user.lastname?.length > 0) {
      if (user.firstname?.length > 0) {
        return user.firstname + ' ' + user.lastname;
      } else {
        return user.lastname;
      }
    } else if (user.username?.length > 0) {
      return user.username;
    } else if (user.email?.length > 0) {
      return user.email;
    } else if (user.keycloak_username?.length > 0) {
      return user.keycloak_username;
    } else {
      return 'No Identity';
    }
  }

  /**
   * Get the currently selected mandator and business
   * @returns mandator and business
   */
  public getManBus(): IMandatorBusinessResponse {
    return {
      mandator: this.currentMandator,
      business: this.currentBusiness,
    };
  }

  /**
   * Get the currently settings to use
   * @returns settings
   */
  public getSettings(): SettingsModel {
    return this.settings;
  }

  /**
   * Is the current user a inside user?
   * @returns if is inside
   */
  public get isInside(): boolean {
    return !!this.currentUser?.groups.find(
      (g) => g.group_name === 'Innendienst'
    );
  }

  /**
   * Is the current user a inside user?
   * @returns if is inside
   */
  public get isOutside(): boolean {
    return !!this.currentUser?.groups.find(
      (g) => g.group_name === 'Aussendienst'
    );
  }

  /**
   * Is the current user a inside user?
   * @returns if is inside
   */
  public get isFiller(): boolean {
    return !!this.currentUser?.groups.find((g) => g.group_name === 'Befüllung');
  }

  /**
   * Is the current user a admin?
   * @returns if is admin
   */
  public get isAdmin(): boolean {
    return this.currentUser?.is_admin;
  }

  /**
   * Benutzer abmelden, Tokens resetten, Keycloak abmelden
   */
  public logoutFull() {
    this.resetUser();
    localStorage.removeItem('kc_refreshToken');
    localStorage.removeItem('kc_token');
  }

  /**
   * Trys to set the currently selected mandator
   * @param mandator Mandator to select
   * @returns successfully changed mandator?
   */
  public async setMandator(mandator: MandatorModel): Promise<boolean> {
    if (!mandator) return false;
    this.currentMandator = await firstValueFrom(
      this.mandatorService.find(mandator.id)
    );
    this.currentUser.mandator = this.currentMandator;
    this.currentUser.businesses = await (
      await firstValueFrom(
        this.businessService.where({ mandator_id: this.currentMandator.id })
      )
    ).data;
    return true;
  }

  /**
   * Trys to set the currently selected business
   * @param business Business to select
   * @returns successfully changed business?
   */
  public setBusiness(business: BusinessModel): boolean {
    if (this.currentUser?.businesses.includes(business)) {
      this.currentBusiness = business;
      return true;
    }
    return false;
  }

  /**
   * Loads the current user
   */
  public async loadUser(): Promise<UserModel> {
    const resUser = await firstValueFrom(this.userService.current_user());
    this.setUser(resUser);
    this.events.publish('userHelper:loadedUser', this.currentUser);
    this._userLoaded = true;
    try {
      resUser.car = resUser.car
        ? await firstValueFrom(this.carService.find(resUser.car.id))
        : null;
      console.debug('[UserHelper] Car for user got loaded!', resUser.car);
    } catch (error) {
      console.warn(
        'Could not load car for user, if sync is in progress, will try again after sync!',
        error
      );
      return resUser;
    }
    this.currentMandator = resUser.mandator;
    this.currentBusiness = resUser.businesses[0];
    this.generateSettings();
    return this.currentUser;
  }

  /**
   * Resets all UserData (for Logout)
   */
  public resetUser(): void {
    this.setUser(null);
  }

  /**
   * Regenerates the settings wich are currently active for the user
   */
  private generateSettings() {
    const generated: SettingsModel = {} as SettingsModel;
    if (!this.currentBusiness || !this.currentBusiness.settings) {
      this.settings = this.currentMandator.settings;
      return;
    }
    Object.keys(this.currentBusiness.settings).forEach((k) => {
      const value = this.currentBusiness.settings[k];
      if (value) {
        generated[k] = value;
      } else {
        generated[k] = this.currentMandator.settings[k];
      }
    });
    this.settings = generated;
  }
}
