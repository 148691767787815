import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { ITemplate } from '../models/template';
import { instanceOfITemplateColumn } from '../models/tp-col';

const TRANSLATION_TYPES = {
  Order: 'Serviceauftrag',
  'Reporting::DamageReport': 'Schadensbericht',
  'ChecklistManagement::WriteableChecklist': 'Checkliste',
};

const NESTED_ATTRIBUTE_KEYS = [
  'rows',
  'cols',
  'element_style',
  'module',
  'border',
  'border_radius',
  'border_type',
  'border_color',
  'border_weight',
  'padding',
  'margin',
  'title_text_style',
  'body_text_style',
];

@Injectable({
  providedIn: 'root',
})
export class TemplateService extends OfflineDataService<ITemplate> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'TemplateManagement::Document',
      http,
      errorService,
      events,
      'reporting/documents/',
      'document',
      ['created_at', 'updated_at', 'created_by_id'],
      []
    );
  }

  /**
   * Übersetzt einen type
   * @param {string} type
   * @return {string}
   */
  public static translateType(type: string): string {
    return TRANSLATION_TYPES[type];
  }

  /**
   * Übersetzungen zurückgeben
   * @return {Record<string,string>}
   */
  public static get translations(): Record<string, string> {
    return TRANSLATION_TYPES;
  }

  /**
   * Speichern überladen und Objekt streamlinen bevor es versendet wird
   * @param {ITemplate} object
   * @return {Observable<ITemplate>}
   */
  public override saveRemote(object: ITemplate): Observable<ITemplate> {
    const mappedObject = this.mapAndFilterObject(object);
    return super.saveRemote(mappedObject);
  }

  /**
   * Object Streamlinen
   * @param obj to map and filter
   * @return finished object
   */
  private mapAndFilterObject(obj: ITemplate): ITemplate {
    if (!obj) return obj;
    for (const k of Object.keys(obj)) {
      if (typeof obj[k] === 'object') {
        // Checks if Type is Column and if it is and the Column has no element or rows it gets deleted
        if (obj[k] && instanceOfITemplateColumn(obj[k])) {
          if (!obj[k].module && (!obj[k].rows || obj[k].rows.length <= 0)) {
            delete obj[k];
          }
        }
        this.mapAndFilterObject(obj[k]);
      }
      this.checkAndAddAttributed(obj, k);
    }
    return obj;
  }

  /**
   * Checks if and applies 'attributes' to obj attribute k
   * @param obj obj to check
   * @param k key to check
   */
  private checkAndAddAttributed(obj: any, k: string) {
    if (NESTED_ATTRIBUTE_KEYS.includes(k)) {
      const v = obj[k];
      delete obj[k];
      obj[k + '_attributes'] = v;
    }
  }
}
