import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { StockMovementModel } from '../models/stockMovement';
import { Observable, catchError, retry } from 'rxjs';
import { StoredData } from '@vending/sync-engine-client/dist/sync-engine-client/lib/models/storedData';

@Injectable({
  providedIn: 'root',
})
export class StockMovementService extends OfflineDataService<StockMovementModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Warehouse::StockMovement',
      http,
      errorService,
      events,
      'warehouse/stock_movements/',
      'stock_movement',
      [],
      []
    );
  }

  /**
   * post-request to start the inventory
   * @param bufferstockId ID of bufferstock inventory is started for
   * @param userId ID of current user
   * @returns inventory
   */
  letsMoveTheBooking(
    stock_movement_id: number,
    userId: number
  ): Observable<StockMovementModel> {
    const params = new HttpParams()
      .set('verified_by_id', userId)
      .set('stock_movement_id', stock_movement_id);
    return this.http
      .post<any>(this.endpointWithUrl + 'book_movement', {}, { params })
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Falls verfied gesetzt ist, local löschen und nicht einfügen!
   * Wichtig: Weil wir sonst den Speicher der Endgeräte vollmüllen.
   * @param object
   * @returns
   */
  async saveLocal(
    object: StockMovementModel
  ): Promise<boolean | StoredData<StockMovementModel>> {
    if (object.verified_by_id > 0) {
      await this.localDelete(object.id);
      return Promise.resolve(true);
    } else {
      return super.saveLocal(object);
    }
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'file-tray-stacked-outline';
  }
}
