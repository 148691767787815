<ion-list inset class="debugList ion-no-padding" style="margin: 0px;">
  <ion-item lines="none">
    <ion-text class="colorDebug">
      Sie bleiben bis zum Schließen der/des App/Browsers im <strong>Entwicklermodus</strong>.
    </ion-text>
  </ion-item>
  <ion-item button detail (click)="open('/dev/offline-data')">
    <ion-icon name="layers-outline" class="colorDebug" slot="start"></ion-icon>
    <ion-label class="colorDebug">
      <strong>Offlinedaten anzeigen</strong>
    </ion-label>
    <mits-badge color="debug" [value]="devHelper.totalOfflineDataAmount || 0"></mits-badge>
  </ion-item>
  <ion-item button detail (click)="open('/settings/function_switches')" *ngIf="userHelper.isAdmin"
    [disabled]="!systemHelper.isOnline">
    <ion-icon name="toggle-outline" class="colorDebug" slot="start"></ion-icon>
    <ion-label class="colorDebug">
      <strong>Funktionsschalter anzeigen</strong>
    </ion-label>
  </ion-item>
  <ion-item button detail (click)="open('/dev/performance')" *ngIf="systemHelper.isDeveloper && userHelper.isAdmin">
    <ion-icon name="stopwatch-outline" class="colorDebug" slot="start"></ion-icon>
    <ion-label class="colorDebug">
      <strong>Performance</strong>
    </ion-label>
  </ion-item>
  <ion-item button detail (click)="reloadApp()" *ngIf="userHelper.isAdmin">
    <ion-icon name="reload-outline" class="colorDebug" slot="start"></ion-icon>
    <ion-label class="colorDebug">
      <strong>App neu laden</strong>
    </ion-label>
  </ion-item>
  <ion-item button detail (click)="open('/statusboard')" [disabled]="!systemHelper.isOnline" lines="none">
    <ion-icon name="alert-outline" class="colorDebug" slot="start"></ion-icon>
    <ion-label class="colorDebug">
      <strong>Statusboard anzeigen</strong>
    </ion-label>
    <div class="infoBadgesSlot" slot="end" *ngIf="appComp.errorLogHelper.errorlogs">
      <mits-badge color="light" *ngIf="appComp.errorLogHelper.hasInfos()"
        [value]="appComp.errorLogHelper.errorCounters.infos">
      </mits-badge>
      <mits-badge color="warning" *ngIf="appComp.errorLogHelper.hasWarnings()"
        [value]="appComp.errorLogHelper.errorCounters.warnings">
      </mits-badge>
      <mits-badge color="danger" *ngIf="appComp.errorLogHelper.hasErrors()"
        [value]="appComp.errorLogHelper.errorCounters.errors">
      </mits-badge>
    </div>
  </ion-item>
  <ion-item button detail class="colorBgDebug" (click)="exitDevMode()">
    <ion-icon name="exit-outline" color="light" slot="start"></ion-icon>
    <ion-label color="light">
      Entwicklermodus verlassen
    </ion-label>
  </ion-item>
</ion-list>