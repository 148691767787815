import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client/dist/sync-engine-client';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable, Subscriber } from 'rxjs';
import { TimelogModel } from 'src/app/models/system/timelog';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';
import { OfflineDataService } from '../../offlineData.service';
import { QueueEntry } from '@vending/sync-engine-client/dist/sync-engine-client/lib/models/queueEntry';

@Injectable({
  providedIn: 'root',
})
export class TimelogService extends OfflineDataService<TimelogModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'System::Timelog',
      http,
      errorService,
      events,
      'system/timelogs/',
      'timelog',
      ['created_at', 'updated_at', 'remote_id'],
      ['modules'],
      null,
      true
    );
  }

  async findQueue(id: any): Promise<QueueEntry<TimelogModel>> {
    return await this.queueService.getQueuedId(id);
  }

  async updateQueue(obj: any): Promise<number> {
    return await this.queueService.updateQueued(obj);
  }

  findRemote(id: any): Observable<any> {
    return new Observable((observer: Subscriber<any>) =>
      observer.error({ httpStatus: 404, message: 'Not found' })
    );
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'timer-outline';
  }
}
