export class GlobalHelper {
  /**
   * Erstellt eine tiefe Kopie eines Objektes
   * @param {T} obj
   * @return {T}
   */
  public static deepClone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * Gibt immer einen String zurück. Leerer String wenn Null
   * @param value
   * @returns
   */
  public static nullToEmptyString(value: string | null): string {
    return value || '';
  }

  /**
   * Markiert ein Element als gelöscht oder löscht es aus dem Array
   * @param array
   * @param index
   */
  public static markForDestroyOrSplice(array: any[], index: number) {
    if (array[index].id > 0) {
      array[index]._destroy = true;
    } else {
      array.splice(index, 1);
    }
  }

  /**
   * Gibt zurück ob eine Zahl 0, null oder undefined ist
   * @param {number} value
   * @return {boolean}
   */
  public static isZero(value: number | null | undefined): boolean {
    return value === 0 || this.isNullOrUndefined(value);
  }

  public static isNumber(value: any): boolean {
    if (value === null || value === undefined) return false;
    value = Number(value);
    return !Number.isNaN(value) && (value === 0 || value > 0 || value < 0);
  }

  /**
   * Prüft ob value null oder undefined ist
   * @param value
   * @returns
   */
  public static isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }

  /**
   * Entfernt das Feld ID aus den Objekten
   * @param objects
   * @returns
   */
  public static removeIdFromObjects<T>(objects: T[]): T[] {
    return objects.map((obj) => {
      delete obj['id'];
      return obj;
    });
  }

  /**
   * Vergleich von zwei Iso Dates.
   * aDateIso >= bDateIso
   * @param {string} aDateIso
   * @param {string} bDateIso
   * @return {boolean}
   */
  public static dateGreaterEquals(aDateIso: string, bDateIso: string): boolean {
    const d1 = new Date(aDateIso);
    const d2 = new Date(bDateIso);

    return d1.getTime() >= d2.getTime();
  }

  /**
   * Substrahiert Datum A von Datum B auf Zeitebene
   * Hilfsfunktion zum Sortieren
   * @param {string | Date} aDateIso
   * @param {string | Date} bDateIso
   * @return {boolean}
   */
  public static substractDates(
    aDateIso: string | Date,
    bDateIso: string | Date
  ): number {
    const dateA = new Date(aDateIso);
    const dateB = new Date(bDateIso);
    return dateB.getTime() - dateA.getTime();
  }

  /**
   * Einen moment Warten und ein Schläfchen machen zZzzZZz
   * @param {number} time
   */
  public static async sleep(time: number) {
    await new Promise((r) => setTimeout(r, time));
  }

  /**
   * Base64 in ein File umwandeln
   * @param url
   * @param filename
   * @returns
   */
  public static async base64ToFile(
    url: string,
    filename: string
  ): Promise<File> {
    const res = await fetch(url);
    const blob = await res.blob();
    return new File([blob], filename, { type: 'image/png' });
  }

  /**
   * Logs a deep copy of the data
   * @param data to log
   */
  public static deepLogger(data: any, info: string) {
    console.debug(
      `[DEBUG]: ${info}`,
      data ? JSON.parse(JSON.stringify(data)) : data
    );
  }
}
