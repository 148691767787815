<ion-app>
  <ion-split-pane contentId="main" when="lg">
    <ion-menu #ionicMenu contentId="main" menuId="mainMenu">
      <ion-header style="padding: 4px">
        <app-logo></app-logo>
      </ion-header>
      <ion-content *ngIf="sideMenu && userHelper.getManBus()">
        <ion-item>
          <ion-label position="floating">Betriebsstätte</ion-label>
          <ion-select [(ngModel)]="selectedBusiness" [compareWith]="compareWith"
            [disabled]="userHelper.getUser()?.businesses?.length <= 1" (ionChange)="changedBusiness()"
            okText="Auswählen" cancelText="Abbrechen">
            <ion-select-option *ngFor="let business of userHelper.getUser()?.businesses" [value]="business">
              {{ business.name1 }} {{ business.name2 }} {{ business.name3 }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <div class="{{ group.cssClass }}" *ngFor="let group of sideMenu.groups">
          <span *ngIf="group.accessGranted && !group.hidden">
            <ion-item class="menu-heading" *ngIf="group.title" [title]="group.title" expand="full" lines="full">
              <span class="menu-heading" expand="full">
                {{ group.title }}
              </span>
            </ion-item>
            <div *ngFor="let element of group.elements" [title]="element.title">
              <ion-item *ngIf="
                  element.accessGranted &&
                  (element.offlineSupported || systemHelper.isOnline) &&
                  !element.subElements && !element.hidden
                " [ngClass]="{
                  active: element.name && this.selectedPage === element.name
                }" [title]="element.title" (click)="openPage(element)" lines="full">
                <ion-icon [name]="element.icon" [color]="
                    element.name && this.selectedPage === element.name
                      ? 'primary'
                      : element.color
                  ">
                </ion-icon>
                <ion-label [color]="element.color"> {{ element.title }} </ion-label>
                <mits-badge *ngIf="element.badge" [color]="element.badge.color" [value]="element.badge.text" slot="end">
                </mits-badge>
              </ion-item>
              <div *ngIf="element.subElements">
                <ion-item *ngIf="
                    element.accessGranted && !element.hidden &&
                    (element.offlineSupported || systemHelper.isOnline)
                  " [ngClass]="{
                    active: element.name && this.selectedPage === element.name
                  }" [title]="element.title" (click)="changeSubElementsVisible(element)" lines="full">
                  <ion-icon [name]="element.icon" [color]="
                      element.name && this.selectedPage === element.name
                        ? 'light'
                        : element.color
                    "></ion-icon>
                  <ion-label class="ion-padding" [color]="element.color"> {{ element.title }} </ion-label>
                  <ion-icon class="colorGrey" *ngIf="!element.subelementsVisible" name="arrow-down" slot="end">
                  </ion-icon>
                  <ion-icon class="colorGrey" *ngIf="element.subelementsVisible" name="arrow-up" slot="end"></ion-icon>
                </ion-item>
                <div class="menuSubList" *ngIf="element.subelementsVisible">
                  <ng-container *ngFor="let subElement of element.subElements">
                    <ion-item *ngIf="
                        subElement.accessGranted && !subElement.hidden &&
                        (element.offlineSupported || systemHelper.isOnline)
                      " [ngClass]="{
                        active:
                          subElement.name && selectedPage === subElement.name
                      }" [title]="subElement.title" (click)="openPage(subElement)" lines="full">
                      <ion-icon [name]="subElement.icon" [color]="subElement.color"></ion-icon>
                      <ion-label> {{ subElement.title }} </ion-label>
                      <mits-badge *ngIf="subElement.badge" [color]="subElement.badge.color"
                        [value]="subElement.badge.text" slot="end">
                      </mits-badge>
                    </ion-item>
                  </ng-container>
                </div>
              </div>
            </div>
          </span>
        </div>
      </ion-content>
      <ion-footer class="ion-text-center">
        <ion-item
          *ngIf="(errorLogHelper.hasAny() && userHelper.isAdmin) || (errorLogHelper.hasWarnings() || errorLogHelper.hasErrors())"
          lines="full" [detail]="this.userHelper.isAdmin"
          [color]="errorLogHelper.hasErrors() ? 'danger' : errorLogHelper.hasWarnings() ? 'warning' : 'medium'"
          [routerLink]="this.userHelper.isAdmin? 'statusboard' : '.'" class="animatePulseOpacity"
          (click)="errorLogHelper.reloadErrorlogs()">
          <ion-icon
            [name]=" errorLogHelper.hasErrors() || errorLogHelper.hasWarnings() ? 'warning-outline' : 'information-circle-outline'"
            size="default" [ngClass]="{'animatePulseScale': errorLogHelper.hasErrors()}" slot="start">
          </ion-icon>
          <ion-text>
            {{ errorLogHelper.hasErrors() || errorLogHelper.hasWarnings() ? 'Einrichtung nicht abgeschlossen!' :
            'Informationen zur weiteren Einrichtung' }}
          </ion-text>
        </ion-item>
        <ion-item class="logged_in" lines="full">
          <ion-icon name="search"></ion-icon>
          <span style="padding: 4px 0px 4px 16px">
            <strong>Status der Suche:</strong>
            <lib-fulltext-counter class="insideMenu"></lib-fulltext-counter>
          </span>
        </ion-item>
        <div class="infoItem" *ngIf="!systemHelper.isBackendOnline">
          <span>Server nicht erreichbar</span>
          <p>
            Die App funktioniert nur stark eingeschränkt, da der Server nicht erreichbar ist.<br>
            Bitte melden Sie sich sofort bei Ihrem Administrator.
          </p>
        </div>
        <div class="infoItem" *ngIf="!systemHelper.isOnline">
          <span>Sie sind Offline</span>
          <p>
            Einige Features können ausgeblendet sein, da diese Offline nicht
            verfügbar sind.
          </p>
        </div>
        <ion-item class="logged_in" *ngIf="userHelper.getUser()" lines="full">
          <ion-icon [name]="systemHelper.currentEvent === 'christmas' ? '' : 'person-outline'"></ion-icon>
          <mits-icon *ngIf="systemHelper.currentEvent === 'christmas'" name="person-christmas"></mits-icon>
          <ion-label>
            <div>Angemeldet als:</div>
            <strong>
              {{userHelper.getUserString(userHelper.getUser())}}
            </strong>
          </ion-label>
        </ion-item>
        <ion-item [hidden]="!systemHelper.isDeveloper" detail button class="animatePulseOpacity colorBgDebug"
          id="dev-trigger">
          <ion-icon name="warning-outline" color="light" slot="start" size="default"
            class="animatePulseScale"></ion-icon>
          <ion-text color="light">Entwicklermodus aktiviert!</ion-text>
        </ion-item>
        <ion-popover class="devPopover" trigger="dev-trigger" triggerAction="click">
          <ng-template>
            <dev-options></dev-options>
          </ng-template>
        </ion-popover>
        <ion-item *ngIf="systemHelper.isDownloadRunning && systemHelper.isOnline" class="animatePulseOpacity"
          color="warning">
          <ion-icon name="sync-outline" color="dark" slot="start" size="default" class="animateRotate"></ion-icon>
          <ion-text color="dark">
            <strong>Synchronisation läuft...</strong>
            <span class="infoTextAlert">Währenddessen können in der App Unterbrechungen auftreten</span>
          </ion-text>
        </ion-item>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>