import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mits-number-box',
  templateUrl: './mits-number-box.component.html',
  styleUrls: ['./mits-number-box.component.scss'],
})
export class MitsNumberBoxComponent {
  /** Value of the number-input */
  @Input() value = 0;
  /** Minimal accepted value */
  @Input() minValue?;
  /** Maximal accepted value */
  @Input() maxValue?;
  /** Steps in wich the component should in-/decrease the value on button-click */
  @Input() stepValue = 1;
  /** Should the input and the buttons be disabled? */
  @Input() disabled = false;
  /** If set the number-box will be shown as a item with an fixed label on the left side */
  @Input() label = '';
  /** Output Changed-Event of value */
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  /**
   * Sets value and emit change-event
   * @param event event of changed input-field
   */
  changedValue(event: any): void {
    this.checkSetValue(Number(event.target.value));
    this.valueChange.emit(this.value);
  }

  /**
   * Changes/Subtracts value by step
   */
  clickSubtract(): void {
    if (this.disabled) {
      return;
    }
    this.checkSetValue(this.value - this.stepValue);
    this.valueChange.emit(this.value);
  }

  /**
   * Changes/Adds value by step
   */
  clickAdd(): void {
    if (this.disabled) {
      return;
    }
    this.checkSetValue(this.value + this.stepValue);
    this.valueChange.emit(this.value);
  }

  /**
   * Checks if the provided value is correct and sets it
   * @param val check and set this value
   * @returns was value correct?
   */
  private checkSetValue(val: number): boolean {
    if (val > this.maxValue) {
      this.value = this.maxValue;
      return false;
    } else if (val < this.minValue) {
      this.value = this.minValue;
      return false;
    } else {
      this.value = val;
      return true;
    }
  }
}
